import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';

import { Copy, FilePlus, TimeClock, Search, Activity, Minus, CheckLarge, ChevronDown2, Minus2, ChevronUp, ChevronUp2, ChevronDown, UserPlus } from "../components/icons.js";
import { Status, Button, Counter, Input, Avatar } from '../components/component.js';
import Loading from '../components/loading.js';
import Drop from '../components/drop.js';
import Helmet from "../components/helmet.js";
import Editor from "../components/editor.js";
import UserPicker from "../components/userPicker.js";
import LocationPicker from "../components/locationPicker.js";
import { DatePicker, SlotPicker } from "../components/datePicker.js";

function PatientCaregiverModal(props) {
  const { data, setVisible, saveCallback } = props;

  const context = useContext(AppContext);

  const [name, setName] = useState(data.name);
  const [role, setRole] = useState(data.role);
  const [email, setEmail] = useState(data.email);
  const [phone, setPhone] = useState(data.phone);

  function save() {
    let u = context.addSpiner();
    axios.post('/api/admin/patient/profiles/updateCaregiver', {
      id: data.id,
      patientID: data.patientID,
      name: name,
      role: role,
      email: email,
      phone: phone,
    }).then((response) => {
      data.id = response.data.id;
      data.name = name;
      data.role = role;
      data.email = email;
      data.phone = phone;
      saveCallback(data);
      setVisible(false);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  return (
    <div className="fixed-bottom modal-bg" onClick={(e) => setVisible(false)}>
      <div className="container" style={{paddingTop: '5%'}}>
        <div className="row">
          <div className="col-xs-6 col-xs-offset-3">
            <div className="card shadowmore" onClick={(e) => {e.stopPropagation()}}>
              <div className="card-header">
                <span className="typo-header-3xLarge">{data.index ? "Edit" : "New"} caregiver</span><br/>
                <span className="typo-normal-175">Change caregiver information and click Save</span>
              </div>
              <div className="card-content mb-4">
                <div className="row mb-3">
                  <div className="col-xs-6 pe-4">
                    <div className="typo-strong-175">Name</div>
                    <input type="text" placeholder="Title" value={name} onChange={(e) => setName(e.target.value)} className="input borderless typo-normal-175 w-100" />
                  </div>
                  <div className="col-xs-6 ps-4">
                    <div className="typo-strong-175">Role</div>
                    <input type="text" placeholder="Value" value={role} onChange={(e) => setRole(e.target.value)} className="input borderless typo-normal-175 w-100" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6 pe-4">
                    <div className="typo-strong-175">Email</div>
                    <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="input borderless typo-normal-175 w-100" />
                  </div>
                  <div className="col-xs-6 ps-4">
                    <div className="typo-strong-175">Phone</div>
                    <input type="text" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} className="input borderless typo-normal-175 w-100" />
                  </div>
                </div>
              </div>
              <div className="card-content text-end mt-4">
                <Button onClick={save} color="blue" text="Save" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientCaregiverModal;
