import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { Badge, BadgeInformative, BadgeNegative, BadgeNeutral } from '../components/component.js';
import { Avatar, Status, Button } from "../components/component.js";
import { AlarmClock, Hashtag, TimeClock } from "../components/icons.js";
import Helmet from "../components/helmet.js";
import Editor from "../components/editor.js";

import { AppContext } from '../context.js';

import { TimestampToDate, DateToTimestamp, DateToFormat, NowToTimestamp } from "../utils/datetime.js";

function Dashboard() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  const [today, setToday] = useState(new Date());

  const [loading, setLoading] = useState(true);

  const [tasks, setTasks] = useState([]);
  const [tasksOverdue, setTasksOverdue] = useState([]);
  const [tasksOverdueSoon, setTasksOverdueSoon] = useState([]);
  const [tasksRest, setTasksRest] = useState([]);

  const [taskStatuses, setTaskStatuses] = useState([]);
  const [taskLimitStep, setTaskLimitStep] = useState(3);
  const [taskLimit, setTaskLimit] = useState(taskLimitStep);

  const [appointments, setAppointments] = useState([]);
  const [appointmentStatuses, setAppointmentStatuses] = useState([]);
  const [appointmentLimitStep, setAppointmentLimitStep] = useState(5);
  const [appointmentLimit, setAppointmentLimit] = useState(appointmentLimitStep);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/care/tasks/list', {
        userID: [context.userID],
        status: [1, 2, 3],
      }),
      axios.post('/api/admin/care/appointments/list', {
        userID: [context.userID],
        status: [1, 2, 3],
      }),
      axios.post('/api/admin/care/tasks/statuses/list', {
        // TODO
      }),
      axios.post('/api/admin/care/appointments/statuses/list', {
        // TODO
      }),
    ]).then(axios.spread((tasks, appointments, taskStatuses, appointmentStatuses) => {
      setTasks(tasks.data.tasks);

      setTasksOverdue(tasks.data.tasks.filter((t) => t.deadline && DateToTimestamp(new Date()) > t.deadline));
      setTasksOverdueSoon(tasks.data.tasks.filter((t) => t.deadline && DateToTimestamp(new Date()) < t.deadline && DateToTimestamp(new Date()) + 86400 > t.deadline));
      setTasksRest(tasks.data.tasks.filter((t) => !((t.deadline && DateToTimestamp(new Date()) > t.deadline) || (t.deadline && DateToTimestamp(new Date()) < t.deadline && DateToTimestamp(new Date()) + 86400 > t.deadline))));
      
      setAppointments(appointments.data.appointments);
      setTaskStatuses(taskStatuses.data.statuses);
      setAppointmentStatuses(appointmentStatuses.data.statuses);
      setLoading(false);
    })).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  function renderTaskStatistics() {
    const assigned = tasks.filter((t) => t.assignedAt + 86400 > NowToTimestamp()).length;
    return "" + tasks.length + (assigned > 0 ? " +" + assigned : "");
  }

  function renderTasks(list, isLengthLimit) {
    if (isLengthLimit) {
      list = list.slice(0, taskLimit);
    }
    return list.map((task, ti) => {
      return (
        <div key={"task_" + task.id} className="mb-2">
          <div className="card clickable" onClick={() => navigate('/project/' + task.projectID)}>
            <div className="card-header sm">
              <div className="d-flex justify-content-between align-items-center">
                <div className="typo-strong-200 w-100">{task.title}</div>
                <div className="d-flex justify-content-end align-items-center w-25">
                  {renderTaskStatus(task.statusID)}
                </div>
              </div>

              {task.description && (
                // <div className="typo-normal-150 w-100">{task.description}</div>
                <Editor 
                  value={task.description}
                  readOnly={true}
                  toolbar="full"
                  className="text-editor borderless"
                />
              )}

              <div className="d-flex justify-content-start align-items-center mt-1">
                {task.deadline && (
                  <div className="item grey">
                    <span className="typo-normal-150">Due date:</span>
                    <span className="typo-normal-150">{DateToFormat(TimestampToDate(task.deadline), 'DD M3 YYYY')}</span>
                  </div>
                )}

                {(task.deadline && DateToTimestamp(new Date()) < task.deadline && DateToTimestamp(new Date()) + 86400 > task.deadline) ? (
                  <div className="d-flex justify-content-start align-items-center ms-2 me-2">
                    <div className="item yellow">
                      <TimeClock height={16} width={16} background={'none'} fill={'darkorange'} />
                      <span className="typo-normal-150">Due Date Overdue Soon</span>
                    </div>
                  </div>
                ) : null}

                {(task.deadline && DateToTimestamp(new Date()) > task.deadline) ? (
                  <div className="d-flex justify-content-start align-items-center ms-2 me-2">
                    <div className="item red">
                      <TimeClock height={16} width={16} background={'none'} fill={'firebrick'} />
                      <span className="typo-normal-150">Due Date Overdue</span>
                    </div>
                  </div>
                ) : null}

                <div className="d-flex justify-content-start align-items-center">
                  <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{task.taskTypeName}</span>
                </div>

                {task.patientTask && (
                  <div className="d-flex justify-content-start align-items-center ms-3 text-brand">
                    <Hashtag height={16} width={16} background={'none'} fill={'#113997'} /> <span className="typo-normal-150">Patient task</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  function renderTasksLimit(list) {
    let tasks = list;

    if (tasks.length <= taskLimitStep) {
      return <></>;
    }

    let more = taskLimit + taskLimitStep;
    if (more > tasks.length) {
      more = tasks.length;
    }

    let less = taskLimit - taskLimitStep;
    if (less < taskLimitStep) {
      less = taskLimitStep;
    }

    return (
      <div className="d-flex justify-content-center align-items-center">
        {tasks.length > taskLimit && (
          <div className="item typo-normal-150 me-2" onClick={() => setTaskLimit(more)}>Show more</div>
        )}
        {taskLimit > taskLimitStep && (
          <div className="item typo-normal-150 me-2" onClick={() => setTaskLimit(less)}>Show less</div>
        )}
        {taskLimit !== tasks.length && (
          <div className="item typo-normal-150 me-2" onClick={() => setTaskLimit(tasks.length)}>Show all</div>
        )}
        {taskLimit !== taskLimitStep && (
          <div className="item typo-normal-150 me-2" onClick={() => setTaskLimit(taskLimitStep)}>Show minimum</div>
        )}
      </div>
    );
  }

  function renderAppointments() {
    return appointments.slice(0, appointmentLimit).map((appointment, ai) => {
       return (
        <div key={"appointment_" + ai} className="mb-2">
          <div className="card clickable" onClick={() => navigate('/project/' + appointment.projectID)}>
            <div className="card-header sm">
              <div className="d-flex justify-content-between align-items-top">

                <div className="d-flex justify-content-between align-items-top" style={{width: 120}}>
                  <div className="pe-3" style={{width: 40}}>
                    <div className="typo-strong-300 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), 'DD')}</div>
                    <div className="typo-strong-150 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), 'M3').toUpperCase()}</div>
                  </div>
                  <div style={{width: 80}}>
                    <div className="typo-strong-175 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
                    <div className="typo-normal-125 text-center text-brand">{appointment.duration} min</div>
                    <div className="typo-strong-175 text-center text-brand">{DateToFormat(TimestampToDate(appointment.endAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
                  </div>
                </div>

                <div className="w-100 ms-3 ps-3" style={{borderLeft: '1px solid lightgrey'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="typo-strong-200 w-100">{appointment.title}</div>
                    <div className="d-flex justify-content-end align-items-center w-25">
                      {renderAppointmentStatus(appointment.statusID)}
                    </div>
                  </div>
                  
                  {appointment.description && (
                    <Editor 
                      value={appointment.description}
                      readOnly={true}
                      toolbar="full"
                      className="text-editor borderless"
                    />
                    // appointment.description.split("\r\n").map((line, li) => (
                    //   <div className="typo-normal-150 w-100">{line}</div>
                    // ))
                  )}
                    
                  <div className="d-flex justify-content-between align-items-center mt-1">
                    <div className="d-flex justify-content-start align-items-center w-75">
                      {/* <UserPreview
                        userList={users}
                        userID={appointment.userID}
                        onChange={() => {}}
                        onChangeID={appointment.id}
                        leftText="Assignee:"
                        rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                      /> */}
                      
                      {/* <div className="d-flex justify-content-start align-items-center ms-4">
                        <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{appointment.appointmentTypeName}</span>
                      </div> */}
                      <div className="d-flex justify-content-start align-items-center">
                        <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{appointment.appointmentTypeName}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  function renderAppointmentsLimit() {
    if (appointments.length <= appointmentLimitStep) {
      return <></>;
    }

    let more = appointmentLimit + appointmentLimitStep;
    if (more > appointments.length) {
      more = appointments.length;
    }

    let less = appointmentLimit - appointmentLimitStep;
    if (less < appointmentLimitStep) {
      less = appointmentLimitStep;
    }

    return (
      <div className="d-flex justify-content-center align-items-center">
        {appointments.length > appointmentLimit && (
          <div className="item typo-normal-150 me-2" onClick={() => setAppointmentLimit(more)}>Show more</div>
        )}
        {appointmentLimit > appointmentLimitStep && (
          <div className="item typo-normal-150 me-2" onClick={() => setAppointmentLimit(less)}>Show less</div>
        )}
        {appointmentLimit !== appointments.length && (
          <div className="item typo-normal-150 me-2" onClick={() => setAppointmentLimit(appointments.length)}>Show all</div>
        )}
        {appointmentLimit !== appointmentLimitStep && (
          <div className="item typo-normal-150 me-2" onClick={() => setAppointmentLimit(appointmentLimitStep)}>Show minimum</div>
        )}
      </div>
    );
  }

  function renderTaskStatus(id) {
    const status = taskStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial || status.isPaused) {
      statusColor = "grey";
    } 

    if (status.isCompleted) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={'small'} text={status.title} />
    );
  }

  function renderAppointmentStatus(id) {
    const status = appointmentStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial || status.isPaused) {
      statusColor = "grey";
    } 

    if (status.isCompleted) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={'small'} text={status.title} />
    );
  }

  if (loading) {
    return (
      <></>
    );
  }

  return (
    <>
      <Helmet title="Dashboard" />

      <div className="container-fluid pb-4">
        <div className="row mb-4">
          <div className="col-xs-offset-1 col-xs-10">
            <div className="card mb-2">
              <div className="card-content d-flex justify-content-between align-items-center">
                <div className="w-25">
                  <div className="typo-hero-small">Hello, Marvin</div>
                  <div className="typo-normal-175">This is an overview of your work</div>
                </div>
                <div className="w-25 ms-3 ps-3" style={{borderLeft: '1px solid lightgrey'}}>
                  <div className="typo-strong-200">Projects</div>
                  <div className="typo-hero-small">2</div>
                </div>
                <div className="w-25 ms-3 ps-3" style={{borderLeft: '1px solid lightgrey'}}>
                  <div className="typo-strong-200">Tasks</div>
                  <div className="typo-hero-small">{tasks.length}</div>
                  {/* <span className="text-green">{renderTaskStatistics()}</span> */}
                </div>
                <div className="w-25 ms-3 ps-3" style={{borderLeft: '1px solid lightgrey'}}>
                  <div className="typo-strong-200">Appointments</div>
                  <div className="typo-hero-small">{appointments.length}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-offset-1 col-xs-5 pe-4">
            {tasksOverdue.length > 0 && (
              <>
                <div className="card shadowmore mb-2">
                  <div className="card-content sm d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="typo-strong-200">Overdue tasks</span>
                    </div>
                  </div>
                </div>
                {renderTasks(tasksOverdue, false)}
                <div className="mb-4"></div>
              </>
            )}

            {tasksOverdueSoon.length > 0 && (
              <>
                <div className="card shadowmore mb-2">
                  <div className="card-content sm d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="typo-strong-200">Overdue soon tasks</span>
                    </div>
                  </div>
                </div>
                {renderTasks(tasksOverdueSoon, false)}
                <div className="mb-4"></div>
              </>
            )}

            <div className="card shadowmore mb-2">
              <div className="card-content sm d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                  <span className="typo-strong-200">Tasks</span>
                </div>
              </div>
            </div>
            {renderTasks(tasksRest, true)}
            {renderTasksLimit(tasksRest)}
            
          </div>
          <div className="col-xs-5 ps-4">
            <div className="card shadowmore mb-2">
              <div className="card-content sm d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                  <span className="typo-strong-200">Appointments</span>
                </div>
              </div>
            </div>
            {renderAppointments()}
            {renderAppointmentsLimit()}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
