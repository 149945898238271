import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate, Link } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';
import { Copy, FilePlus, TimeClock, Search, UnfoldMore, MarkerPin, MoreVertical, ChevronUp, File, Comment, ChevronDown, ChevronDown2, ChevronDown3, ChevronUp2, ChevronUp3, Minus2, Hashtag, Filter } from "../components/icons.js";

import { Avatar, Status, Button, Counter } from '../components/component.js';
import Loading from '../components/loading.js';
import Helmet from "../components/helmet.js";

import UserPreview from "../components/userPreview.js";
import LocationPreview from "../components/locationPreview.js";

function Billing() {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet title="Billings" />

      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="card-content">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="typo-strong-200">Billing coming soon</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Billing;
