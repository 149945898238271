import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, useNavigate, useParams } from "react-router-dom";

import { AppContext } from '../context.js';
import { Copy, FilePlus, TimeClock, Search, UnfoldMore, MarkerPin, MoreVertical, ChevronUp, File, Comment, ChevronDown, ChevronDown2, ChevronDown3, ChevronUp2, ChevronUp3, Minus2, Hashtag, Filter, Edit } from "../components/icons.js";
import { Avatar, Status } from "../components/component.js";
import Loading from "../components/loading.js";
import axios from "axios";

import UserPreview from "../components/userPreview.js";
import LocationPreview from "../components/locationPreview.js";

import { FilesPreview } from "../components/filePreview.js";
import { TimestampToDate, DateToTimestamp, DateToFormat, calculateAge } from "../utils/datetime.js";

import PatientCustomAttributeModal from "./patient-custom-attribute-modal.js";
import PatientCaregiverModal from "./patient-caregiver-modal.js";

function Patient() {
  const context = useContext(AppContext);

  let { id } = useParams();

  let patientID = Number(id);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [profile, setProfile] = useState(null);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);

  const [customAttributeModalData, setCustomAttributeModalData] = useState({
    visible: false,
    id: null,
    index: null,
    title: null,
    value: null,
  });

  const [caregiverModalData, setCaregiverModalData] = useState({
    visible: false,
    id: null,
    patientID: null,
    name: null,
    role: null,
    email: null,
    phone: null,
  });

  const imageUploadRef = useRef(null);
  const fileUploadRef = useRef(null);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/patient/profiles/get', {
        id: patientID
      }),
      axios.post('/api/admin/care/projects/list', {
        patientID: [patientID]
      }),
      axios.post('/api/admin/iam/users/list', {
        // TODO
      }),
      axios.post('/api/admin/org/locations/list', {
        // TODO
      }),
    ]).then(axios.spread((profile, projects, users, locations) => {
      setProfile(profile.data);
      setProjects(projects.data.projects);
      setUsers(users.data.users);
      setLocations(locations.data.locations);
      setLoading(false);
    })).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  // Custom attributes
  function renderCustomAttributes() {
    return profile.customAttributes.map((attribute, i) => {
      return (
        <div className="d-flex justify-content-start align-items-center item mb-1" onClick={() => openPatientCustomAttributeModal(profile.id, i, attribute.title, attribute.value)}>
          
            <div className="typo-strong-175 w-50 pe-2 text-start">
              {attribute.title}
            </div>
            <div className="typo-normal-175 w-50 ps-2 text-start">
              {attribute.value}
            </div>
          
        </div>
      );
    });
  }

  function openPatientCustomAttributeModal(id, index, title, value) {
    setCustomAttributeModalData({
      visible: true,
      id: id,
      index: index,
      title: title,
      value: value,
    });
  }

  function saveCustomAttributeCallback(data) {
    let newCustomAttributes = profile.customAttributes;

    if (data.index === null) {
      newCustomAttributes.push({title: data.title, value: data.value});
    } else {
      newCustomAttributes[data.index] = {title: data.title, value: data.value};
    }

    setProfile({...profile, customAttributes: newCustomAttributes});
  }

  // Caregivers
  function renderCaregivers() {
    if (profile.caregivers.length === 0) {
      return <div className="typo-normal-150">No data</div>;
    }

    return profile.caregivers.map((caregiver) => {
      return (
        <div className="d-flex justify-content-between align-items-center item" onClick={() => openPatientCaregiverModal(caregiver.id, profile.id, caregiver.name, caregiver.role, caregiver.email, caregiver.phone)}>
          <div className="d-flex justify-content-start align-items-center">
            <span className="typo-strong-175 me-1">{caregiver.name}</span>
            {caregiver.role && (
              <span className="typo-normal-175 me-3">({caregiver.role})</span>
            )}
            <span className="typo-normal-175 me-3">{caregiver.email}</span>
            <span className="typo-normal-175">{caregiver.phone}</span>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            {/* <MoreVertical height={16} width={16} background={'none'} fill={'grey'} /> */}
          </div>
        </div>
      );
    });
  }

  function openPatientCaregiverModal(id, patientID, name, role, email, phone) {
    setCaregiverModalData({
      visible: true,
      id: id,
      patientID: patientID,
      name: name,
      role: role,
      email: email,
      phone: phone,
    });
  }

  function saveCaregiverCallback(data) {
    let newCaregivers = profile.caregivers;

    const existingCaregiverIndex = newCaregivers.findIndex(caregiver => caregiver.id === data.id);

    if (existingCaregiverIndex !== -1) {
      newCaregivers[existingCaregiverIndex] = data;
    } else {
      newCaregivers.push(data);
    }

    setProfile({...profile, caregivers: newCaregivers});
  }

  // Patient profile image
  function changeImageInput(e) {
    var data = new FormData();
    data.append('patientID', profile.id);
    data.append('image', e.target.files[0]);
    
    let u = context.addSpiner();
    axios.post('/api/admin/iam/users/updateImage', data).then((response) => {
      setProfile({...profile, profileImageName: response.data.fileName});
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  // Patient files
  function triggerFileInput() {
    fileUploadRef.current.click();
  }

  function changeFileInput(e) {
    if (e.target.files.length === 0) {
      return;
    }

    var data = new FormData();
    data.append('fileLength', e.target.files.length);
    data.append('patientID', profile.id)

    for (var i = 0; i < e.target.files.length; i++) {
      data.append('file'+i, e.target.files[i]);
    }
    
    let u = context.addSpiner();
    axios.post('/api/admin/patient/profiles/uploadFile', data).then((response) => {
      setProfile({...profile, files: profile.files.concat(response.data.files)});
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  // Projects
  function renderProjects() {
    return projects.map((project) => {
      return (
        <div onClick={() => navigate(`/project/${project.id}`)} className="card clickable mb-2">
          <div className="card-content">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center typo-strong-300">
                {renderProjectPriority(project)}
                  
                <span className="typo-strong-200 ms-3">P-00000{project.id}</span>

                <span className="typo-strong-200 ms-4">{'No title'}</span>
                  
                <div className="d-flex justify-content-start align-items-center ms-4">
                  <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{project.projectTypeName}</span>
                </div>

              </div>
              <div className="d-flex justify-content-end align-items-center w-25">
                {renderProjectStatus(project)}
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-1">
                
              <div className="d-flex justify-content-start align-items-center me-3">
                <UserPreview
                  userList={users.filter(user => user.roleID === 2)}
                  userID={project.userID}
                  leftText="Assignee:"
                />
              </div>

              <div className="d-flex justify-content-start align-items-center me-3">
                <UserPreview
                  userList={users.filter(user => user.roleID === 3)}
                  userID={project.patientID}
                  leftText="Patient:"
                />
              </div>

              <div className="d-flex justify-content-start align-items-center me-3">
                <LocationPreview
                  locationList={locations}
                  locationID={project.locationID}
                  leftText="Location:"
                />
              </div>
                
            </div>
          </div>
        </div>
      );
    });
  }

  function renderProjectPriority(project) {
    switch (project.priority) {
      case -2:
        return <ChevronDown2 height={20} width={20} background={'none'} fill={'blue'} />;
      case -1:
        return <ChevronDown height={16} width={16} background={'none'} fill={'blue'} />;
      case 0:
        return <Minus2 height={16} width={16} background={'none'} fill={'orange'} />;
      case 1:
        return <ChevronUp height={16} width={16} background={'none'} fill={'red'} />;
      case 2:
        return <ChevronUp2 height={16} width={16} background={'none'} fill={'red'} />;
      default:
        return <Minus2 height={16} width={16} background={'none'} fill={'orange'} />;
    }
  }

  function renderProjectStatus(project) {
    var statusColor = "blue";

    if (project.statusIsInitial) {
      statusColor = "grey";
    } 

    if (project.statusIsComplete) {
      statusColor = "green";
    }

    return (
      <Status color={statusColor} muted={true} size={"small"} text={project.statusName} />
    );
  }

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <Loading />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <input ref={imageUploadRef} type="file" className="d-none" onChange={changeImageInput} accept=".jpg,.jpeg,.png,.tiff,.pdf" multiple={false} />
      <input ref={fileUploadRef} type="file" className="d-none" onChange={changeFileInput} accept=".jpg,.jpeg,.png,.tiff,.pdf" multiple={true} />

      {customAttributeModalData.visible && (
        <PatientCustomAttributeModal 
          data={customAttributeModalData}
          setVisible={() => setCustomAttributeModalData({...customAttributeModalData, visible: false})}
          saveCallback={saveCustomAttributeCallback}
        />
      )}

      {caregiverModalData.visible && (
        <PatientCaregiverModal 
          data={caregiverModalData}
          setVisible={() => setCaregiverModalData({...caregiverModalData, visible: false})}
          saveCallback={saveCaregiverCallback}
        />
      )}

      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-offset-2 col-xs-3">
            <div className="card">
              <div className="card-header sm">
                <div className="d-flex justify-content-between align-items-top">
                  <div className="d-flex justify-content-start align-items-center typo-strong-400">
                    <Avatar url={profile.profileImageName} onClick={() => imageUploadRef.current.click()} alt="logo" style={{height: 50, width: 50}} />
                    <div className="ms-3">
                      <div className="typo-strong-300">{profile.firstName} {profile.lastName}</div>
                      <div className="typo-normal-200">
                        {profile.gender && (
                          <span className="me-2">{profile.gender === 1 ? "Male" : "Female"}</span>
                        )}
                        {profile.dateOfBirth && (
                          <span className="me-2">{calculateAge(profile.dateOfBirth) + " years (" + DateToFormat(TimestampToDate(profile.dateOfBirth), context.dateFormat) + ")"}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>              
              </div>
              <div className="card-content text-center sm">
                {renderCustomAttributes()}
                <div className="item typo-normal-150 mt-1" onClick={() => openPatientCustomAttributeModal(profile.id, null, null, null)}>
                  <FilePlus height={16} width={16} background={'none'} fill={'grey'} />
                    Add field
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-header sm">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="typo-strong-175 w-100">Caregivers</div>
                  <div className="item typo-normal-150" onClick={() => openPatientCaregiverModal(null, profile.id, null, null, null)}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Add</div>
                </div>
              </div>
              <div className="card-content sm">
                {renderCaregivers()}
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-header sm">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="typo-strong-175 w-100">Documents</div>
                  <div className="item typo-normal-150" onClick={() => triggerFileInput()}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Upload</div>
                </div>
              </div>
              <div className="card-content sm">
                <FilesPreview files={profile.files} />
              </div>
            </div>
          </div>

          <div className="col-xs-5">
            <div className="ps-4">
              <div className="card shadowmore mb-2">
                <div className="card-content sm d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-start align-items-center">
                    <span className="typo-strong-200">Projects</span>
                    <div className="item typo-normal-150 ms-3 active">All</div>
                  </div>
                  
                  <div className="item typo-normal-150 ms-2" onClick={() => navigate('/project/create')}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Create</div>
                </div>
              </div>

              {renderProjects()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Patient;
