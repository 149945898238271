import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import axios from 'axios';

import Logo from "../files/logo.svg";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, UserPlus, ChevronDown } from "../components/icons.js";

import { Avatar, Counter } from "../components/component.js";
import Drop from "./drop.js";

import { AppContext } from '../context.js';


function UserPreview(props) {
  const { userList, userID, leftText, leftIcon, rightIcon, className } = props;

  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);

  const [passedUserID, setPassedUserID] = useState(userID);
  const [selectedUser, setSelectedUser] = useState(users.find((u) => u.id === passedUserID));

  const [closeUserDrop, setCloseUserDrop] = useState(0);

  useEffect(() => {
    if (userList) {
      setUsers(userList);
      setLoading(false);
      return;
    }
    
    let u = context.addSpiner();
    axios.post('/api/admin/iam/users/list', {
      // id: id,
      // userID: userID
    }).then((response) => {
      setUsers(response.data.users);
      setLoading(false);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  useEffect(() => {
    setPassedUserID(userID);
    setSelectedUser(users.find((u) => u.id === userID));
  }, [userID, loading]);

  if (loading) {
    return <></>;
  } 

  return (
    <Drop close={closeUserDrop}>
      <div className={"item grey " + className}>
        {leftIcon && leftIcon}
        {leftText && <span className="typo-normal-150">{leftText}</span>}
        {selectedUser ? (
          <Avatar url={selectedUser.profileImageName} />
        ) : (
          <UserPlus height={16} width={16} background={'none'} fill={'grey'} />
        )}
        <span className="typo-normal-150">{selectedUser ? selectedUser.firstName + " " + selectedUser.lastName + (selectedUser.roleID === 2 && selectedUser.speciality ? ' | ' + selectedUser.speciality : '') : "Unknown"}</span>
        {rightIcon && rightIcon}
      </div>
      {selectedUser ? selectedUser.roleID === 2 ? (
        <div className="card shadowmore" style={{width: 'fit-content'}}>
          <div className="card-content sm">
            <span className="typo-normal-150">User profile</span>
          </div>
        </div>
      ) : (
        <div className="card shadowmore" style={{width: 400}}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <Avatar url={selectedUser.profileImageName} style={{height: 40, width: 40}} />
              <div className="d-flex justify-content-between align-items-top w-100 ps-2">
                <div>
                  <span className="typo-strong-200">{selectedUser.firstName + " " + selectedUser.lastName}</span>
                  <div className="typo-normal-150">45 years old, 01/15/1979, Male</div>
                </div>
                <span className="typo-normal-150">MRN: {"selectedUser.profile.mrn"}</span>
              </div>
            </div>
          </div>
          {/* {selectedUser.profile.caregivers && (
            <div className="card-content">
              <div className="typo-strong-150">Caregiver{selectedUser.profile.caregivers.length > 1 ? 's' : ''}</div>
              {selectedUser.profile.caregivers.map((c) => {
                return <div className="typo-normal-150 mb-1">{c.name} {c.phone}</div>
              })}
            </div>
          )} */}
          
          {/* <div className="card-content">
            <div className="typo-strong-125">Most Recent Diagnosis</div>
            <div className="typo-normal-150 mb-3">Hypertension</div>

            <div className="typo-strong-125">Next Appointment Date</div>
            <div className="typo-normal-150 mb-3">10/30/2024</div>

            <div className="typo-strong-125">Current Status</div>
            <div className="typo-normal-150 mb-3">Follow-up Required</div>

            <div className="typo-strong-125">Recent Activity</div>
            <div className="typo-normal-150 mb-3">Lab results reviewed on 10/18/2024; pending follow-up for medication adjustment</div>

            <div className="typo-strong-125">Care Team</div>
            <div className="typo-normal-150 mb-3">Dr. Sarah Thompson (Primary Care Physician), Jane Smith (Care Coordinator)</div>

            <div className="typo-strong-125">Communication Preference</div>
            <div className="typo-normal-150">Phone call (preferred time: 9 AM - 12 PM)</div>
            
          </div> */}
        </div>
      ) : (
        <div className="card shadowmore" style={{width: 'fit-content'}}>
          <div className="card-content sm">
            <span className="typo-normal-150">No profile</span>
          </div>
        </div>
      )}
      
    </Drop>
  );
}

export default UserPreview;