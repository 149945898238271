import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate, Link } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';
import { Copy, FilePlus, TimeClock, Search, UnfoldMore, MarkerPin, MoreVertical, ChevronUp, File, Comment, ChevronDown, ChevronDown2, ChevronDown3, ChevronUp2, ChevronUp3, Minus2, Hashtag, Filter } from "../components/icons.js";

import { Avatar, Status, Button, Counter } from '../components/component.js';
import Loading from '../components/loading.js';
import Helmet from "../components/helmet.js";

import UserPreview from "../components/userPreview.js";
import LocationPreview from "../components/locationPreview.js";

import { TimestampToDate, DateToTimestamp, DateToFormat, calculateAge } from "../utils/datetime.js";

function Patients() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState([]);
  const [filterSelected, setFilterSelected] = useState(0);
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/user/filters/patients/list', {
        // TODO add user id
      }),
      axios.post('/api/admin/patient/profiles/list', {
        // TODO add user id
      }),
    ]).then(axios.spread((filters, profiles) => {
      setFilters(filters.data.filters);
      setProfiles(profiles.data.profiles);
      setLoading(false);
    })).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  function renderPatients() {
    return profiles.map((profile) => {
      return (
        <div onClick={() => navigate(`/patient/${profile.id}`)} className="card clickable mb-2">
          <div className="card-content">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center">
                <Avatar url={profile.profileImageName} alt="logo" style={{height: 50, width: 50}} />
                <div className="ms-3">
                  <div className="typo-strong-200">{profile.firstName} {profile.lastName}</div>
                  <div className="typo-normal-175">
                    {profile.gender && (
                      <span className="me-2">{profile.gender === 1 ? "Male" : "Female"}</span>
                    )}
                    {profile.dateOfBirth && (
                      <span className="me-2">{calculateAge(profile.dateOfBirth) + " years"}</span>
                    )}
                  </div>
                </div>
                
                  
                {/* <div className="d-flex justify-content-start align-items-center ms-4">
                  <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{project.projectTypeName}</span>
                </div> */}

              </div>
              {/* <div className="d-flex justify-content-end align-items-center w-25">
                123
              </div> */}
            </div>
          </div>
        </div>
      );
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet title="Patients" />

      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="card mb-4">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  {/* <div className="d-flex align-items-center text-muted">
                    <Search height={16} width={16} background={'none'} fill={'grey'} /> <span className="ms-1">Type to search...</span>
                  </div> */}
                  <div className="d-flex align-items-center">
                    <div className="item rect" onClick={() => setFiltersExpanded(!filtersExpanded)}>
                      <Filter height={14} width={16} background={'none'} fill={'grey'} />
                    </div>
                    <div className={"item ms-2 " + (filterSelected === 0 ? "active" : "")} onClick={() => setFilterSelected(0)}>
                      <span className="typo-normal-150">All</span>
                    </div>
                    {filters.map((filter) => {
                      return (
                        <div className={"item ms-2 " + (filterSelected === filter.id ? "active" : "")} onClick={() => setFilterSelected(filter.id)}>
                          <span className="typo-normal-150">{filter.title}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <div className="item typo-normal-150 ms-2" onClick={() => navigate('/patient/create')}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Create</div>
                  </div>
                </div>
              </div>
              {filtersExpanded && (
                <div className="card-content">
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="typo-normal-150">Filters coming soon</div>
                  </div>
                </div>
              )}
            </div>
            {renderPatients()}
          </div>
        </div>
      </div>
    </>
  );
}

export default Patients;
