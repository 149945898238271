import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate, Link } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../../context.js';
import { Copy, FilePlus, TimeClock, Search, UnfoldMore, MarkerPin, MoreVertical, ChevronUp, File, Comment, ChevronDown, ChevronDown2, ChevronDown3, ChevronUp2, ChevronUp3, Minus2, Hashtag, Filter } from "../../components/icons.js";

import { Avatar, Status, Button, Counter } from '../../components/component.js';
import Loading from '../../components/loading.js';
import Helmet from "../../components/helmet.js";

function ProgramAttrition() {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);

  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/analytic/care/program-attrition', {
        
      }),
    ]).then(axios.spread((programs) => {
      setPrograms(programs.data.programs);
      setLoading(false);
    })).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);
    

  function renderAnalytics() {
    return programs.map((item, index) => {
      return (
        <div key={index} className="card mb-3">
          <div className="card-header">
            <span className="typo-strong-200">{item.title}</span><br/>
            <span className="typo-normal-150">{item.description}</span>
          </div>
          <div className="card-content">
            <div className="row">
              <div className="col-xs-3">
              <div className="typo-strong-200">Total: {item.total}</div>
              </div>
              <div className="col-xs-3">
                <div className="typo-strong-200">Active: {item.active}</div>
              </div>
              <div className="col-xs-3">
                <div className="typo-strong-200">Completed: {item.completed}</div>
                {item.completedReasons && item.completedReasons.map((reason, index) => {
                  return (
                    <div key={index} className="typo-normal-150">{reason.title}: {reason.total}</div>
                  );
                })}
              </div>
              <div className="col-xs-3">
                <div className="typo-strong-200">Cancelled: {item.cancelled}</div>
                {item.cancelledReasons && item.cancelledReasons.map((reason, index) => {
                  return (
                    <div key={index} className="typo-normal-150">{reason.title}: {reason.total}</div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  if (loading) {
    return (
      <>
        <Helmet title="Program Attrition" />
        <Loading />
      </>
    );
  }

  return (
    <>
      <Helmet title="Program Attrition" />

      <div className="container">
        <div className="row">
          <div className="col-xs-10 col-xs-offset-1">
            <div className="card mb-3">
              <div className="card-header">
                <span className="typo-header-3xLarge">Program Attrition</span><br/>
                <span className="typo-normal-175">Description</span>
              </div>
              {/* <div className="card-header">
                <div className="typo-strong-200">Program Attrition</div>
              </div> */}
            </div>
        
            {renderAnalytics()}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgramAttrition;
