import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';

import { Button } from '../components/component.js';

import Background from '../files/background.png';
import LogoDark from '../files/logo-dark.png';

import HipaaCG from '../files/hipaa-cg.png';
import HipaaCMS from '../files/hipaa-cms.png';
import HipaaCR from '../files/hipaa-cr.png';

function Login() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  function onClickLogin() {
    let u = context.addSpiner();
    axios.post('/api/public/login', {
      email: login,
      password: password
    }).then((r) => {
      context.setState(
        r.data.token, 
        r.data.roleID,
        r.data.userID, 
        r.data.firstName + ' ' + r.data.lastName, 
        r.data.profileImageName, 
        r.data.language, 
        r.data.dateFormat, 
        r.data.timeFormat,
        'light'
      );
      if (r.data.roleID === 2) navigate("/projects");
      if (r.data.roleID === 3) navigate("/project/1");
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  return (
    <div className="d-flex justify-content-between align-items-center h-100 w-100" style={{backgroundColor: '#11254F'}}>
              
      <div className="d-flex justify-content-center align-items-center w-100">
        <div style={{width: 400, paddingBottom: '15%'}}>
          <img src={LogoDark} className="w-50 mb-4" />

          <div className="typo-strong-300" style={{color: "white"}}>Welcome back</div>

          <div className="typo-medium-150 mb-4" style={{color: "white"}}>Enter your username and password to continue</div>

          <div className="typo-normal-125 mb-1" style={{color: "white"}}>Username</div>
          <input type="text" placeholder="Login" value={login} onChange={(e) => setLogin(e.target.value)} className="input w-100 mb-2" />
      
          <div className="typo-normal-125 mb-1" style={{color: "white"}}>Password</div>
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="input w-100 mb-4" />
        
          <Button color="blue" text="Log In" onClick={onClickLogin} className="w-100" />

          <br/>
          <br/>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <img src={HipaaCR} className="" style={{height: 70}} />
            <img src={HipaaCMS} className="ms-4" style={{width: 70}} />
            <img src={HipaaCG} className="ms-4" style={{height: 70}} />
          </div>

          {/* <br/>
          <br/>
          <br/>
          <br/> */}

          {/* <div>
            <div className="typo-strong-300 mb-1" style={{color: "white"}}>Refer & Earn with Carecierge!</div>
            <div className="typo-medium-150 mb-4" style={{color: "white"}}>Empower your network and enjoy the rewards! Invite colleagues and peers to join Carecierge, the leading solution for task management platform for Care Teams</div>

            <Button color="blue" text="Learn more about referral program" onClick={() => navigate("/referral")} className="w-100" />

          </div> */}
          

        </div>
      </div>
      <img src={Background} className="" />
    </div>
  );
}

export default Login;
