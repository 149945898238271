import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';

import { Copy, FilePlus, TimeClock, Search, Activity, Minus, CheckLarge, ChevronDown2, Minus2, ChevronUp, ChevronUp2, ChevronDown, UserPlus, Calendar } from "../components/icons.js";
import { Status, Button, Counter, Input, Avatar } from '../components/component.js';
import Loading from '../components/loading.js';
import Drop from '../components/drop.js';
import Helmet from "../components/helmet.js";
import Editor from "../components/editor.js";
import UserPicker from "../components/userPicker.js";
import LocationPicker from "../components/locationPicker.js";
import { DatePicker, SlotPicker, DateSelector } from "../components/datePicker.js";
import { TimestampToDate, DateToTimestamp, DateToFormat } from "../utils/datetime.js";

function TaskCreateModal(props) {
  const { projectID, projectTypeID, setVisible, createTaskCallback } = props;

  const context = useContext(AppContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);

  const [type, setType] = useState(null);
  const [user, setUser] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [instruction, setInstruction] = useState('');
  const [patientTask, setPatientTask] = useState(false);
  const [deadline, setDeadline] = useState(null);

  const [valid, setValid] = useState(false);
  const [validationMessages, setValidationMessages] = useState([]);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/care/tasks/types/list', {
        projectTypeID: projectTypeID,
      }),
      axios.post('/api/admin/iam/users/list', {
        roleID: 2,
      }),
    ]).then(axios.spread((types, users) => {
      setTypes(types.data.types);
      if (types.data.types.length > 0) {
        setType(types.data.types[0].id);
        setTitle(types.data.types[0].defaultTitle ? types.data.types[0].defaultTitle : '');
        setDescription(types.data.types[0].defaultDescription ? types.data.types[0].defaultDescription : '');
        setInstruction(types.data.types[0].defaultInstruction ? types.data.types[0].defaultInstruction : '');
      }
      setUsers(users.data.users);
      setLoading(false);
    })).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  useEffect(() => {
    let valid = true;
    let messages = [];

    if (!type) {
      valid = false;
      messages.push('Project type is required');
    }

    // if (!location && location < 1) {
    //   valid = false;
    //   messages.push('Location is required');
    // }

    // if (!patient) {
    //   valid = false;
    //   messages.push('Patient is required');
    // }

    setValid(valid);
    setValidationMessages(messages);
  }, [type]);

  function changeType(type) {
    setType(type.id);
    setTitle(type.defaultTitle ? type.defaultTitle : '');
    setDescription(type.defaultDescription ? type.defaultDescription : '');
    setInstruction(type.defaultInstruction ? type.defaultInstruction : '');
  }

  function updateDescription(text, id) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }

    setDescription(text);
  }

  function updateInstruction(text, id) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }

    setInstruction(text);
  }

  function changeUser(user, id) {
    setUser(user);
  }

  function createTask() {
    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/create', {
      projectID: projectID,
      typeID: type,
      userID: user ? user.id : null,
      //patientID: patient ? patient.id : null,
      //locationID: location ? location.id : null,
      //priority: priority,
      title: title,
      description: description,
      instruction: instruction,
      patientTask: patientTask,
      deadline: deadline ? DateToTimestamp(deadline) : null,
      //tags: tags,
    }).then((response) => {
      setVisible(false);
      createTaskCallback(response.data);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  if (loading) {
    return <></>;
  }

  return (
    <div className="fixed-bottom modal-bg" onClick={(e) => setVisible(false)}>
      <div className="container" style={{paddingTop: '5%'}}>
        <div className="row">
          <div className="col-xs-12">
            <div className="card shadowmore" onClick={(e) => {e.stopPropagation()}}>
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-top">
                  <div>
                    <span className="typo-header-3xLarge">New task</span><br/>
                    <span className="typo-normal-175">Fill all the required fields to create a new task</span>
                  </div>
                  <div>
                    {valid ? (
                      <Button onClick={createTask} color="blue" text="Create" />
                    ) : (
                      <Drop>
                        <Button onClick={createTask} color="blue" text="Create" />
                        <div className="card" style={{width: 200}}>
                          <div className="card-content">
                            {validationMessages.map((m) => {
                              return <div className="typo-normal-150"> - {m}</div>
                            })}
                          </div>
                        </div>
                      </Drop>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-header">
                <div className="row">
                  <div className="col-xs-3 pe-4" style={{minHeight: '400px', borderRight: '1px solid #e0e0e0'}}>
                    <div className="typo-strong-175 mb-2">Task templates</div>
                    {types.map((t) => {
                      return <div key={"type_"+t.id} className={"item w-75 mb-2 " + (type === t.id ? "grey" : "")} onClick={() => changeType(t)}>
                        <div className="w-100">
                          <div className="typo-header-150">{t.title}</div>
                          <div className="typo-normal-125">{t.description}</div>
                        </div>
                      </div>
                    })}
                  </div>
                  <div className="col-xs-6 ps-4 pe-4">
                    <div className="typo-strong-175">Title</div>
                    <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} className="input borderless typo-normal-175 w-100" />

                    <div className="d-flex justify-content-between align-items-top mt-3">
                      <div className="w-50 pe-3">
                        <span className="typo-strong-175">Description:</span>
                        <Editor 
                          placeholder="Type..."
                          value={description}
                          onBlur={updateDescription}
                          toolbar="limited"
                          className="text-editor borderless"
                        />
                      </div>
                      <div className="w-50 ps-4">
                        <span className="typo-strong-175">Patient instruction:</span>
                        <Editor 
                          placeholder="Type..."
                          value={instruction}
                          onBlur={updateInstruction}
                          toolbar="limited"
                          className="text-editor borderless"
                        />
                      </div>
                    </div>

                    {/* <div className="row mt-3">
                      <div className="col-xs-6">
                        <div className="typo-strong-175">Description</div>
                        <textarea className="textarea borderless typo-normal-175 w-100" rows={description ? description.split("\n").length : 1} placeholder="Description..." value={description} onChange={(e) => setDescription(e.target.value)} />
                      </div>
                      <div className="col-xs-6">
                        <div className="typo-strong-175">Instruction</div>
                        <textarea className="textarea borderless typo-normal-175 w-100" rows={instruction ? instruction.split("\n").length : 1} placeholder="Patient instruction..." value={instruction} onChange={(e) => setInstruction(e.target.value)} />
                      </div>
                    </div> */}
                  </div>
                  <div className="col-xs-3">
                    <div className="typo-strong-175">Assignee</div>
                    <UserPicker
                      userList={users}
                      userID={user ? user.id : null}
                      onChange={changeUser}
                      rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    />

                    <div className="typo-strong-175 mt-3">Deadline</div>
                    <DatePicker 
                      date={deadline}
                      onChange={setDeadline}
                      leftText="Due date:"
                      //leftIcon={<Calendar height={16} width={16} background={'none'} fill={'grey'} />}
                      rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    />

                    <div className="typo-strong-175 mt-3">Patient task</div>
                    <div className={"item typo-normal-150 " + (!patientTask ? "active" : "")} onClick={() => setPatientTask(false)}>No</div>
                    <div className={"item typo-normal-150 ms-2 "  + (patientTask ? "active" : "")} onClick={() => setPatientTask(true)}>Yes</div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskCreateModal;
