import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import axios from 'axios';

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, ThumbUp, Heart, Smile, SmileSad, Paperplane, ArrowRight, Comment } from "../components/icons.js";

import { Avatar, Status } from "../components/component.js";

import { AppContext } from '../context.js';

import { TimestampToDate, DateToTimestamp, DateToFormat } from "../utils/datetime.js";

function Events(props) {
  const { projectID, taskID, appointmentID, list, refresh } = props;

  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (list) {
      setEvents(list);
      setLoading(false);
      return;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/communication/events/list', {
      projectID: projectID ? [projectID] : null,
      taskID: taskID ? [taskID] : null,
      appointmentID: appointmentID ? [appointmentID] : null,
    }).then((response) => {
      setEvents(response.data.events);
      setLoading(false);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, [projectID, taskID, appointmentID, list, refresh]);

  if (loading) {
    return (
      <></>
    );
  }

  return (
    <>
      {events.sort((a, b) => b.id - a.id).map((event) => {
        switch (event.type) {
          // Project
          case 'project_created':
            return <ProjectCreated event={event} />;
          case 'project_status_updated':
            return <ProjectStatusUpdated event={event} />;
          case 'project_priority_updated':
            return <ProjectPriorityUpdated event={event} />;
          case 'project_user_updated':
            return <ProjectUserUpdated event={event} />;
          case 'project_watch_list_updated':
            return <ProjectWatchListUpdated event={event} />;
          case 'project_patient_updated':
            return <ProjectPatientUpdated event={event} />;
          case 'project_location_updated':
            return <ProjectLocationUpdated event={event} />;
          case 'project_summary_updated':
            return <ProjectSummaryUpdated event={event} />;
          case 'project_summary_public_updated':
            return <ProjectSummaryPublicUpdated event={event} />;
          
          // Task
          case 'task_created':
            return <TaskCreated event={event} />;
          case 'task_status_updated':
            return <TaskStatusUpdated event={event} />;
          case 'task_patient_visiblity_updated':
            return <TaskPatientVisiblityUpdated event={event} />;
          case 'task_title_updated':
            return <TaskTitleUpdated event={event} />;
          case 'task_description_updated':
            return <TaskDescriptionUpdated event={event} />;
          case 'task_note_updated':
            return <TaskNoteUpdated event={event} />;
          case 'task_instruction_updated':
            return <TaskInstructionUpdated event={event} />;
          case 'task_user_updated':
            return <TaskUserUpdated event={event} />;
          case 'task_deadline_updated':
            return <TaskDeadlineUpdated event={event} />;

          // Appointment
          case 'appointment_created':
            return <AppointmentCreated event={event} />;
          case 'appointment_status_updated':
            return <AppointmentStatusUpdated event={event} />;
          case 'appointment_title_updated':
            return <AppointmentTitleUpdated event={event} />;
          case 'appointment_description_updated':
            return <AppointmentDescriptionUpdated event={event} />;
          case 'appointment_instruction_updated':
            return <AppointmentInstructionUpdated event={event} />;
          case 'appointment_user_updated':
            return <AppointmentUserUpdated event={event} />;

          // Assessment
          case 'assessment_created':
            return <AssessmentCreated event={event} />;
          case 'assessment_status_updated':
            return <AssessmentStatusUpdated event={event} />;
          case 'assessment_user_updated':
            return <AssessmentUserUpdated event={event} />;

          // Message
          case 'message_created':
            return <MessageCreated event={event} />;
          default:
            return null;
        }
      })}
    </>
  )
}

// Project
function ProjectCreated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">created project</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function ProjectStatusUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated project status</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function ProjectPriorityUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated project priority</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function ProjectUserUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">assigned user to project</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function ProjectWatchListUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated watchlist</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function ProjectPatientUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">assigned patient to project</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function ProjectLocationUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated location</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function ProjectSummaryUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated summary</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function ProjectSummaryPublicUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated public summary</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

// Task
function TaskCreated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">created a task</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function TaskStatusUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">changed task status</span>
          <br/>
          <div className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
          <div className="d-flex justify-content-start align-items-center">
            <Status color={"grey"} muted={true} size={'small'} text={content.fromStatusName} className="me-2" />
            <ArrowRight height={14} width={16} background={'none'} fill={'grey'} />
            <Status color={"grey"} muted={true} size={'small'} text={content.toStatusName} className="ms-2" />
          </div>
        </div>
      </div>
    </div>
  );
}

function TaskPatientVisiblityUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">changed task visibility to patient</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function TaskTitleUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated task title</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function TaskDescriptionUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated task description</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function TaskNoteUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated task note</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function TaskInstructionUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated task instruction</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function TaskUserUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">assigned user to task</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function TaskDeadlineUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated task due date</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

// Appointment
function AppointmentCreated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">created an appointment</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function AppointmentStatusUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated appointment status</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function AppointmentTitleUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated appointment title</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function AppointmentDescriptionUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated appointment description</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function AppointmentInstructionUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated appointment instruction</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function AppointmentUserUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">assigned user to appointment</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

// Assessment
function AssessmentCreated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">created assessment</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function AssessmentStatusUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">updated assessment status</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function AssessmentUserUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">assigned user to assessment</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

// Message
function MessageCreated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Comment height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <div><span className="typo-strong-150">{event.createdByName}</span> <span className="typo-medium-150">commented</span></div>
          <div className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
          <div className="item grey">
            <span className="typo-medium-150">{content.content}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;