import { useEffect, useState, useContext } from "react";
import { useNavigate, createBrowserRouter, RouterProvider, Link } from "react-router-dom";

import { AppContext } from '../context.js';

import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import Animation from '../files/cubesAnimation.json';

function Error(props) {
  const { errors } = props;

  const context = useContext(AppContext);

  return (
    <div style={{position: 'fixed', left: 0, bottom: 0, visibility: errors.length > 0 ? 'visible' : 'hidden', height: 80, width: 80}}>
      {errors.map((error) => {
        return (
          <></>
          // <div className="card shadowmore" style={{width: 250}}>
          //   <div className="card-content">
          //     <div className="d-flex justify-content-between align-items-center">
          //       <div className="d-flex justify-content-start align-items-center typo-strong-300">
                  
          //         <span className="typo-strong-200">{error.message}</span>
          //       </div>
          //     </div>
          //   </div>
          // </div>
        );
      })}
    </div>
  );
}

export default Error;