import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, useNavigate, Link, useParams } from "react-router-dom";
import axios from 'axios';

import { Avatar, Status, Button } from "../components/component.js";
import { Copy, FilePlus, TimeClock, MarkerPin, MoreVertical, ChevronUp, Filter, Comment, ChevronDown, ChevronDown2, ChevronUp2, ChevronUp3, Minus2, Hashtag, ArrowRight, Close, UserPlus, User, UserEdit, CalendarEdit, Bank, Refresh, Crown, Home, Eye, ArrowLeft, Bookmark, ListCheck } from "../components/icons.js";
import Editor from "../components/editor.js";
import Drop from "../components/drop.js";
import Chat from "../components/chat.js";
import Events from "../components/events.js";

import { AppContext } from '../context.js';

import TaskCreateModal from "../desktop/task-create-modal.js";
import AppointmentCreateModal from "../desktop/appointment-create-modal.js";
import AssessmentCreateModal from "../desktop/assessment-create-modal.js";

import { TimestampToDate, DateToTimestamp, DateToFormat } from "../utils/datetime.js";

import UserPicker from "../components/userPicker.js";
import UserPreview from "../components/userPreview.js";
import LocationPicker from "../components/locationPicker.js";
import LocationPreview from "../components/locationPreview.js";
import { DatePicker, SlotPicker } from "../components/datePicker.js";
import RecurrencePicker from "../components/recurrencePicker.js";
import ResourcePicker from "../components/resourcePicker.js";

import BillingPicker from "../components/billingPicker.js";
import { FilesPreview } from "../components/filePreview.js";
import { use } from "react";

function Project() {
  const context = useContext(AppContext);

  let { id } = useParams();

  let projectID = Number(id);

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [org, setOrg] = useState(null);

  const [tasks, setTasks] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [assessments, setAssessments] = useState([]);

  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);

  const [summaryTabSelected, setSummaryTabSelected] = useState("summary"); // summary, public
  const [chatTabSelected, setChatTabSelected] = useState("chat"); // chat, public
  const [summaryHeightLimit, setSummaryHeightLimit] = useState(true);

  const [expandedTasksChats, setExpandedTasksChats] = useState([]);
  const [expandedAppointmentsChats, setExpandedAppointmentsChats] = useState([]);
  const [expandedAssessmentsForms, setExpandedAssessmentsForms] = useState([]);
  const [expandedAssessmentsChats, setExpandedAssessmentsChats] = useState([]);

  const [createTaskModalVisible, setCreateTaskModalVisible] = useState(false);
  const [createAppointmentModalVisible, setCreateAppointmentModalVisible] = useState(false);
  const [createAssessmentModalVisible, setCreateAssessmentModalVisible] = useState(false);

  const [showHistory, setShowHistory] = useState(false);
  const [refreshHistory, setRefreshHistory] = useState(0);

  const [isSticky, setIsSticky] = useState(false);
  const [actionTabFilter, setActionTabFilter] = useState("tasks"); // tasks, appointments, assessments, billings, documents
  const [closeActionFilterDrop, setCloseActionFilterDrop] = useState(0);

  // Task Filters
  const [showTodoTasks, setShowTodoTasks] = useState(true);
  const [showPausedTasks, setShowPausedTasks] = useState(true);
  const [showInProgressTasks, setShowInProgressTasks] = useState(true);
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const [showCancelledTasks, setShowCancelledTasks] = useState(false);
  const [showOnlyMyTasks, setShowOnlyMyTasks] = useState(false);

  // Appointment Filters
  const [showTodoAppointments, setShowTodoAppointments] = useState(true);
  const [showPausedAppointments, setShowPausedAppointments] = useState(true);
  const [showInProgressAppointments, setShowInProgressAppointments] = useState(true);
  const [showCompletedAppointments, setShowCompletedAppointments] = useState(false);
  const [showCancelledAppointments, setShowCancelledAppointments] = useState(false);
  const [showOnlyMyAppointments, setShowOnlyMyAppointments] = useState(false);

  // Assessment Filters
  const [showTodoAssessments, setShowTodoAssessments] = useState(true);
  const [showPausedAssessments, setShowPausedAssessments] = useState(true);
  const [showInProgressAssessments, setShowInProgressAssessments] = useState(true);
  const [showCompletedAssessments, setShowCompletedAssessments] = useState(false);
  const [showCancelledAssessments, setShowCancelledAssessments] = useState(false);
  const [showOnlyMyAssessments, setShowOnlyMyAssessments] = useState(false);

  const [closeProjectPriorityDrop, setCloseProjectPriorityDrop] = useState(0);
  const [closeProjectStatusDrop, setCloseProjectStatusDrop] = useState(0);
  const [closeWatchListDrop, setCloseWatchListDrop] = useState(0);
  const [closeTaskStatusDrop, setCloseTaskStatusDrop] = useState(0);
  const [closeAppointmentStatusDrop, setCloseAppointmentStatusDrop] = useState(0);
  const [closeAssessmentStatusDrop, setCloseAssessmentStatusDrop] = useState(0);

  // Project status change confirmation
  const [changeProjectStatusConfirmationModalVisible, setChangeProjectStatusConfirmationModalVisible] = useState(false);
  const [changeProjectStatusConfirmationStatus, setChangeProjectStatusConfirmationStatus] = useState(null);

  const projectFileUploadRef = useRef(null);
  const patientFileUploadRef = useRef(null);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/care/projects/get', {
        id: projectID
      }),
      axios.post('/api/admin/iam/users/list', {
        // TODO
      }),
      axios.post('/api/admin/org/locations/list', {
        // TODO
      }),
      axios.post('/api/admin/org/settings/get', {
        // TODO
      }),
      axios.post('/api/admin/care/tasks/list', {
        projectID: [projectID],
      }),
    ]).then(axios.spread((project, users, locations, org, tasks) => {
      setProject(project.data);
      setUsers(users.data.users);
      setLocations(locations.data.locations);
      setOrg(org.data);
      setTasks(tasks.data.tasks);
      setActionTabFilter("tasks");
      setLoading(false);
    })).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, [id]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 184) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Project
  function changeProjectPriority(priority) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updatePriority', {
      id: project.id,
      priority: priority
    }).then((response) => {
      setProject({ ...project, priority: priority });
      setCloseProjectPriorityDrop(closeProjectPriorityDrop + 1);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeProjectStatus(status) {
    // Cancellation requires a confirmation and a reason
    if (status.isCancelled) {
      setChangeProjectStatusConfirmationStatus(status);
      setChangeProjectStatusConfirmationModalVisible(true);
      //updateProjectStatus(status, project.projectTypeCancellationReasons[0].id, project.projectTypeCancellationReasons[0].title);
      return;
    }

    updateProjectStatus(status, null, null);
  }

  function changeProjectStatusConfirm(status, reasonID, reasonTitle) {
    setChangeProjectStatusConfirmationModalVisible(false);
    setChangeProjectStatusConfirmationStatus(null);
    updateProjectStatus(status, reasonID, reasonTitle);
  }

  function updateProjectStatus(status, reasonID, reasonTitle) {
    let newStatus = 3;
    if (status.isInitial) {
      newStatus = 1;
    }
    if (status.isPaused) {
      newStatus = 2;
    }
    if (status.isCompleted) {
      newStatus = 4;
    }
    if (status.isCancelled) {
      newStatus = 5;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateStatus', {
      id: projectID,
      statusID: status.id,
      reasonID: reasonID,
      reasonTitle: reasonTitle,
    }).then((response) => {
      setProject({ ...project, status: newStatus, statusID: status.id, statusName: status.title });
      setCloseProjectStatusDrop(closeProjectStatusDrop + 1);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeTaskStatus(id, status) {
    let newStatus = 3;
    if (status.isInitial) {
      newStatus = 1;
    }
    if (status.isPaused) {
      newStatus = 2;
    }
    if (status.isCompleted) {
      newStatus = 4;
    }
    if (status.isCancelled) {
      newStatus = 5;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateStatus', {
      id: id,
      statusID: status.id,
    }).then((response) => {
      const updatedTasks = tasks.map((task) => {
        if (task.id === id) {
          return { ...task, status: newStatus, statusID: status.id, statusName: status.title };
        }
        return task;
      });
      setTasks(updatedTasks);
      setCloseTaskStatusDrop(closeTaskStatusDrop + 1);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeAppointmentStatus(id, status) {
    let newStatus = 3;
    if (status.isInitial) {
      newStatus = 1;
    }
    if (status.isPaused) {
      newStatus = 2;
    }
    if (status.isCompleted) {
      newStatus = 4;
    }
    if (status.isCancelled) {
      newStatus = 5;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/appointments/updateStatus', {
      id: id,
      statusID: status.id,
    }).then((response) => {
      const updatedAppointments = appointments.map((appointment) => {
        if (appointment.id === id) {
          return { ...appointment, status: newStatus, statusID: status.id, statusName: status.title };
        }
        return appointment;
      });
      setAppointments(updatedAppointments);
      setCloseAppointmentStatusDrop(closeAppointmentStatusDrop + 1);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeAssessmentStatus(id, status) {
    let newStatus = 3;
    if (status.isInitial) {
      newStatus = 1;
    }
    if (status.isPaused) {
      newStatus = 2;
    }
    if (status.isCompleted) {
      newStatus = 4;
    }
    if (status.isCancelled) {
      newStatus = 5;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/assessments/updateStatus', {
      id: id,
      statusID: status.id,
    }).then((response) => {
      const updatedAssessments = assessments.map((assessment) => {
        if (assessment.id === id) {
          return { ...assessment, status: newStatus, statusID: status.id, statusName: status.title };
        }
        return assessment;
      });
      setAssessments(updatedAssessments);
      setCloseAssessmentStatusDrop(closeAssessmentStatusDrop + 1);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeSummary(text) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateSummary', {
      id: projectID,
      summary: text,
    }).then((response) => {
      setProject({ ...project, summary: text });
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeSummaryPublic(text) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateSummaryPublic', {
      id: projectID,
      summaryPublic: text,
    }).then((response) => {
      setProject({ ...project, summaryPublic: text });
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateProjectUser(user, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateUser', {
      id: id,
      userID: user.id
    }).then((response) => {
      setProject({ ...project, userID: user.id, userName: user.firstName + " " + user.lastName });
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateProjectPatient(patient, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updatePatient', {
      id: id,
      patientID: patient.id
    }).then((response) => {
      setProject({ ...project, patientID: patient.id, patientName: patient.firstName + " " + patient.lastName });
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateProjectLocation(location, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateLocation', {
      id: id,
      locationID: location.id
    }).then((response) => {
      setProject({ ...project, locationID: location.id, locationName: location.name });
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateProjectWatchList() {
    let watching = false;
    if (project.watchList && project.watchList.find((id) => id === context.userID)) {
      watching = true;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateWatchList', {
      id: projectID
    }).then((response) => {
      if (watching) {
        setProject({ ...project, watchList: project.watchList.filter((id) => id !== context.userID) });
      } else {
        setProject({ ...project, watchList: [...project.watchList, context.userID] });
      }
      setCloseWatchListDrop(closeWatchListDrop + 1);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  // Interface
  function switchTab(tab) {
    switch (tab) {
      case "tasks":
        let ut = context.addSpiner();
        axios.post('/api/admin/care/tasks/list', {
          projectID: [project.id],
        }).then((response) => {
          setTasks(response.data.tasks);
          setActionTabFilter(tab);
        }).catch((e) => {
          context.addError(e);
          context.removeSpiner(u);
        }).finally(() => {
          context.removeSpiner(ut);
        });
        break
      case "appointments":
        let ua = context.addSpiner();
        axios.post('/api/admin/care/appointments/list', {
          projectID: [project.id],
        }).then((response) => {
          setAppointments(response.data.appointments);
          setActionTabFilter(tab);
        }).catch((e) => {
          context.addError(e);
          context.removeSpiner(u);
        }).finally(() => {
          context.removeSpiner(ua);
        });
        break
      case "assessments":
        let u = context.addSpiner();
        axios.post('/api/admin/care/assessments/list', {
          projectID: [project.id],
        }).then((response) => {
          setAssessments(response.data.assessments);
          setActionTabFilter(tab);
        }).catch((e) => {
          context.addError(e);
          context.removeSpiner(u);
        }).finally(() => {
          context.removeSpiner(u);
        });
        break
      case "billings":
        setActionTabFilter(tab);
        break
      case "documents":
        setActionTabFilter(tab);
        break
    }
  }

  // Tasks
  function changeTaskTitle(id, text) {
    const updatedTasks = tasks.map((task) => {
      if (task.id === id) {
        return { ...task, title: text };
      }
      return task;
    });
    setTasks(updatedTasks);
  }

  function keyUpTaskTitle(e) {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  function updateTaskTitle(id) {
    const task = tasks.find((task) => task.id === id);
    if (task) {
      let u = context.addSpiner();
      axios.post('/api/admin/care/tasks/updateTitle', {
        id: task.id,
        text: task.title
      }).then((response) => {
        
      }).catch((e) => {
        context.addError(e);
        context.removeSpiner(u);
      }).finally(() => {
        context.removeSpiner(u);
      });
    }
  }

  function updateTaskDescription(text, id) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateDescription', {
      id: id,
      text: text
    }).then((response) => {
      const updatedTasks = tasks.map((task) => {
        if (task.id === id) {
          return { ...task, description: text };
        }
        return task;
      });
      setTasks(updatedTasks);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateTaskNote(text, id) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateNote', {
      id: id,
      text: text
    }).then((response) => {
      const updatedTasks = tasks.map((task) => {
        if (task.id === id) {
          return { ...task, note: text };
        }
        return task;
      });
      setTasks(updatedTasks);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateTaskInstruction(text, id) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateInstruction', {
      id: id,
      text: text
    }).then((response) => {
      const updatedTasks = tasks.map((task) => {
        if (task.id === id) {
          return { ...task, instruction: text };
        }
        return task;
      });
      setTasks(updatedTasks);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateTaskUser(user, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateUser', {
      id: id,
      userID: user.id
    }).then((response) => {
      const updatedTasks = tasks.map((task) => {
        if (task.id === id) {
          return { ...task, userID: user.id, userName: user.firstName + " " + user.lastName };
        }
        return task;
      });
      setTasks(updatedTasks);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateTaskDeadline(date, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateDeadline', {
      id: id,
      deadline: DateToTimestamp(date)
    }).then((response) => {
      const updatedTasks = tasks.map((task) => {
        if (task.id === id) {
          return { ...task, deadline: DateToTimestamp(date) };
        }
        return task;
      });
      setTasks(updatedTasks);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function expandTaskChat(id) {
    if (expandedTasksChats.includes(id)) {
      setExpandedTasksChats(expandedTasksChats.filter((item) => item !== id));
    } else {
      setExpandedTasksChats([...expandedTasksChats, id]);
    }
  }

  function changeTaskPatientVisibility(id, visible) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updatePatientVisibility', {
      id: id,
      visible: visible
    }).then((response) => {
      const updatedTasks = tasks.map((task) => {
        if (task.id === id) {
          return { ...task, patientTask: visible };
        }
        return task;
      });
      setTasks(updatedTasks);
      setCloseTaskStatusDrop(closeTaskStatusDrop + 1);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateTaskBillingCode(code, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateBillingCode', {
      id: id,
      billingCodeID: code.id
    }).then((response) => {
      const updatedTasks = tasks.map((task) => {
        if (task.id === id) {
          return { ...task, billingCodeID: code.id };
        }
        return task;
      });
      setTasks(updatedTasks);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateTaskRecurrence(number, type, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateRecurrence', {
      id: id,
      recurrenceNumber: number,
      recurrenceType: type,
    }).then((response) => {
      const updatedTasks = tasks.map((task) => {
        if (task.id === id) {
          return { ...task, 
            recurrenceAt: response.data.recurrenceAt,
            recurrenceNumber: response.data.recurrenceNumber,
            recurrenceType: response.data.recurrenceType,
          };
        }
        return task;
      });
      setTasks(updatedTasks);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  // Appointments
  function changeAppointmentTitle(id, text) {
    const updatedAppointments = appointments.map((appointment) => {
      if (appointment.id === id) {
        return { ...appointment, title: text };
      }
      return appointment;
    });
    setAppointments(updatedAppointments);
  }

  function keyUpAppointmentTitle(e) {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  function updateAppointmentTitle(id) {
    const appointment = appointments.find((appointment) => appointment.id === id);
    if (appointment) {
      let u = context.addSpiner();
      axios.post('/api/admin/care/appointments/updateTitle', {
        id: appointment.id,
        text: appointment.title
      }).then((response) => {
        
      }).catch((e) => {
        context.addError(e);
        context.removeSpiner(u);
      }).finally(() => {
        context.removeSpiner(u);
      });
    }
  }

  function updateAppointmentDescription(text, id) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/appointments/updateDescription', {
      id: id,
      text: text
    }).then((response) => {
      const updatedAppointments = appointments.map((appointment) => {
        if (appointment.id === id) {
          return { ...appointment, description: text };
        }
        return appointment;
      });
      setAppointments(updatedAppointments);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateAppointmentInstruction(text, id) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/appointments/updateInstruction', {
      id: id,
      text: text
    }).then((response) => {
      const updatedAppointments = appointments.map((appointment) => {
        if (appointment.id === id) {
          return { ...appointment, instruction: text };
        }
        return appointment;
      });
      setAppointments(updatedAppointments);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateAppointmentUser(user, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/appointments/updateUser', {
      id: id,
      userID: user.id
    }).then((response) => {
      const updatedAppointments = appointments.map((appointment) => {
        if (appointment.id === id) {
          return { ...appointment, userID: user.id, userName: user.firstName + " " + user.lastName };
        }
        return appointment;
      });
      setAppointments(updatedAppointments);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function expandAppointmentChat(id) {
    if (expandedAppointmentsChats.includes(id)) {
      setExpandedAppointmentsChats(expandedAppointmentsChats.filter((item) => item !== id));
    } else {
      setExpandedAppointmentsChats([...expandedAppointmentsChats, id]);
    }
  }

  // Assessments
  function updateAssessmentUser(user, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/assessments/updateUser', {
      id: id,
      userID: user.id
    }).then((response) => {
      const updatedAssessments = assessments.map((assessment) => {
        if (assessment.id === id) {
          return { ...assessment, userID: user.id, userName: user.firstName + " " + user.lastName };
        }
        return assessment;
      });
      setAssessments(updatedAssessments);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function expandAssessmentForm(id) {
    // Close chat if open
    if (expandedAssessmentsChats.includes(id)) {
      setExpandedAssessmentsChats(expandedAssessmentsChats.filter((item) => item !== id));
    }

    // Open form
    if (expandedAssessmentsForms.includes(id)) {
      setExpandedAssessmentsForms(expandedAssessmentsForms.filter((item) => item !== id));
    } else {
      setExpandedAssessmentsForms([...expandedAssessmentsForms, id]);
    }
  }

  function expandAssessmentChat(id) {
    // Close form if open
    if (expandedAssessmentsForms.includes(id)) {
      setExpandedAssessmentsForms(expandedAssessmentsForms.filter((item) => item !== id));
    }

    // Open chat
    if (expandedAssessmentsChats.includes(id)) {
      setExpandedAssessmentsChats(expandedAssessmentsChats.filter((item) => item !== id));
    } else {
      setExpandedAssessmentsChats([...expandedAssessmentsChats, id]);
    }
  }

  function updateAssessmentQuestion(id, answer) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/assessments/updateQuestion', {
      id: id,
      answer: answer,
    }).then((response) => {
      const updatedAssessments = assessments.map((assessment) => {
        const updatedQuestions = assessment.questions.map((question) => {
          if (question.id === id) {
            return { ...question, answer: answer, score: response.data.score };
          }
          return question;
        });

        return { ...assessment, questions: updatedQuestions };
      });
      
      setAssessments(updatedAssessments);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateAssessmentRecurrence(number, type, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/assessments/updateRecurrence', {
      id: id,
      recurrenceNumber: number,
      recurrenceType: type,
    }).then((response) => {
      const updatedAssessments = assessments.map((assessment) => {
        if (assessment.id === id) {
          return { ...assessment, 
            recurrenceAt: response.data.recurrenceAt,
            recurrenceNumber: response.data.recurrenceNumber,
            recurrenceType: response.data.recurrenceType,
          };
        }
        return assessment;
      });
      setAssessments(updatedAssessments);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  // Documents
  function triggerProjectFileInput() {
    projectFileUploadRef.current.click();
  }

  function triggerPatientFileInput() {
    patientFileUploadRef.current.click();
  }

  function changeProjectFileInput(e) {
    if (e.target.files.length === 0) {
      return;
    }

    var data = new FormData();
    data.append('fileLength', e.target.files.length);
    data.append('projectID', project.id)

    for (var i = 0; i < e.target.files.length; i++) {
      data.append('file'+i, e.target.files[i]);
    }
    
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/uploadFile', data).then((response) => {
      setProject({...project, files: project.files.concat(response.data.files)});
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changePatientFileInput(e) {
    if (e.target.files.length === 0) {
      return;
    }

    var data = new FormData();
    data.append('fileLength', e.target.files.length);
    data.append('patientID', project.patientID)

    for (var i = 0; i < e.target.files.length; i++) {
      data.append('file'+i, e.target.files[i]);
    }
    
    let u = context.addSpiner();
    axios.post('/api/admin/patient/profiles/uploadFile', data).then((response) => {
      setProject({...project, patientFiles: project.patientFiles.concat(response.data.files)});
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  // Callbacks
  function createMessageCallback() {
    setRefreshHistory(refreshHistory + 1);
  }

  function createTaskCallback(task) {
    setTasks([...tasks, task]);
  }

  function createAppointmentCallback(appointment) {
    setAppointments([...appointments, appointment]);
  }

  function createAssessmentCallback(assessment) {
    setAssessments([...assessments, assessment]);
  }

  // Render helpers
  function renderTasks() {
    //let list = tasks.filter((t) => taskFilter === "all" || (taskFilter === "internal" && !t.patientTask) || (taskFilter === "patient" && t.patientTask))
    let list = tasks;
    if (!showTodoTasks) {
      list = list.filter((t) => t.status !== 1);
    }
    if (!showPausedTasks) {
      list = list.filter((t) => t.status !== 2);
    }
    if (!showInProgressTasks) {
      list = list.filter((t) => t.status !== 3);
    }
    if (!showCompletedTasks) {
      list = list.filter((t) => t.status !== 4);
    }
    if (!showCancelledTasks) {
      list = list.filter((t) => t.status !== 5);
    }
    if (showOnlyMyTasks) {
      list = list.filter((t) => t.userID === context.userID);
    }
      
    return list.map((task, ti) => {
      return (
        <div key={"task_" + task.id} className="mb-2">
          <div className="card">
            <div className="card-header sm">
              <div className="d-flex justify-content-between align-items-center">
              <div className="typo-strong-200 me-2">{task.sequenceNumber}.</div>
                <input type="text" className="input borderless typo-strong-200 w-100" placeholder="Title..." value={task.title} onChange={(e) => changeTaskTitle(task.id, e.target.value)} onBlur={() => updateTaskTitle(task.id)} onKeyUp={(e) => keyUpTaskTitle(e)} />
                <div className="d-flex justify-content-end align-items-center w-25">
                  {renderTaskStatus(task.statusID)}
                  <Drop close={closeTaskStatusDrop} className="ms-1">
                    <div className="item rect">
                      <MoreVertical height={14} width={16} background={'none'} fill={'grey'} />
                    </div>
                    <div className="card" style={{width: 200}}>
                      <div className="card-header sm">
                        <div className="typo-normal-150 text-muted">Change status:</div>
                        {renderTaskNextStatuses(task.id, task.statusID)}
                        <div className="typo-normal-150 text-muted">Change status back to:</div>
                        {renderTaskPrevStatuses(task.id, task.statusID)}
                        <div className="typo-normal-150 text-muted">Patient interaction:</div>
                        {task.patientTask ? (
                          <div className="side-item small typo-medium-150" onClick={() => changeTaskPatientVisibility(task.id, false)}>
                            Hide task from patient
                          </div>
                        ) : (
                          <div className="side-item small typo-medium-150" onClick={() => changeTaskPatientVisibility(task.id, true)}>
                            Show task to patient
                          </div>
                        )}
                      </div>
                    </div>
                  </Drop>
                </div>
              </div>

              <Editor 
                placeholder="Task description..."
                value={task.description}
                onChangeID={task.id}
                onBlur={updateTaskDescription}
                toolbar="limited"
                className="text-editor borderless"
              />

              <div className="d-flex justify-content-between align-items-top mt-2">
                <div className="w-50 pe-3">
                  <span className="typo-strong-150">Note:</span>
                  <Editor 
                    placeholder="Type..."
                    value={task.note}
                    onChangeID={task.id}
                    onBlur={updateTaskNote}
                    toolbar="limited"
                    className="text-editor borderless"
                  />
                </div>
                {task.patientTask && (
                  <div className="w-50 ps-3">
                    <span className="typo-strong-150">Patient instruction:</span>
                    <Editor 
                      placeholder="Type..."
                      value={task.instruction}
                      onChangeID={task.id}
                      onBlur={updateTaskInstruction}
                      toolbar="limited"
                      className="text-editor borderless"
                    />
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-start align-items-center mt-2">
                <div className="d-flex justify-content-start align-items-center">
                  <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{task.taskTypeName}</span>
                </div>

                {task.patientTask && (
                  <div className="d-flex justify-content-start align-items-center ms-3 text-brand">
                    <Hashtag height={16} width={16} background={'none'} fill={'#113997'} /> <span className="typo-normal-150">Patient task</span>
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="d-flex justify-content-start align-items-center w-100">
                  <UserPicker
                    userList={users.filter((u) => u.roleID === 2)}
                    userID={task.userID}
                    onChange={updateTaskUser}
                    onChangeID={task.id}
                    leftIcon={<UserEdit height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className="me-2"
                  />

                  <DatePicker 
                    date={task.deadline ? TimestampToDate(task.deadline) : null}
                    onChange={updateTaskDeadline}
                    onChangeID={task.id}
                    leftIcon={<CalendarEdit height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className={"me-2 " + (task.deadline && DateToTimestamp(new Date()) < task.deadline && DateToTimestamp(new Date()) + 86400 > task.deadline ? "yellow" : "") +
                      (task.deadline && DateToTimestamp(new Date()) > task.deadline  ? "red" : "")
                    }
                  />

                  <RecurrencePicker 
                    recurrenceAt={task.recurrenceAt}
                    recurrenceNumber={task.recurrenceNumber}
                    recurrenceType={task.recurrenceType}
                    onChange={updateTaskRecurrence}
                    onChangeID={task.id}
                    leftIcon={<Refresh height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className="me-2"
                  />

                  <BillingPicker 
                    codeList={project.projectCodes}
                    codeID={task.billingCodeID}
                    onChange={updateTaskBillingCode}
                    onChangeID={task.id}
                    leftIcon={<Bank height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className="me-2"
                  />

                  {/* <ResourcePicker 
                    task={task}
                    leftIcon={<Bookmark height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className="me-2"
                  /> */}
                </div>
                <div className="d-flex justify-content-end align-items-bottom">
                  <div onClick={() => expandTaskChat(task.id)}>
                    {expandedTasksChats.includes(task.id) ? (
                      <div className="item grey">
                        <Comment height={16} width={16} background={'none'} fill={'grey'} />
                        <span className="typo-normal-150">chat</span>
                        <ChevronUp height={14} width={16} background={'none'} fill={'grey'} />
                      </div>
                    ) : (
                      <div className="item grey">
                        <Comment height={16} width={16} background={'none'} fill={'grey'} />
                        <span className="typo-normal-150">chat</span>
                        <ChevronDown height={14} width={16} background={'none'} fill={'grey'} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {expandedTasksChats.includes(task.id) && (
              <div className="card-content sm">
                <Chat
                  chatID={task.chatID}
                  createMessageCallback={createMessageCallback}
                />
              </div>
            )}
          </div>
        </div>
      );
    });
  }

  function renderAppointments() {
    let list = appointments;
    if (!showTodoAppointments) {
      list = list.filter((t) => t.status !== 1);
    }
    if (!showPausedAppointments) {
      list = list.filter((t) => t.status !== 2);
    }
    if (!showInProgressAppointments) {
      list = list.filter((t) => t.status !== 3);
    }
    if (!showCompletedAppointments) {
      list = list.filter((t) => t.status !== 4);
    }
    if (!showCancelledAppointments) {
      list = list.filter((t) => t.status !== 5);
    }
    if (showOnlyMyAppointments) {
      list = list.filter((t) => t.userID === context.userID);
    }

    return list.map((appointment, ai) => {
       return (
        <div key={"appointment_" + ai} className="mb-2">
          <div className="card">
            <div className="card-header sm">
              <div className="d-flex justify-content-between align-items-top">

                <div className="d-flex justify-content-between align-items-top" style={{width: 120}}>
                  <div className="pe-3" style={{width: 40}}>
                    <div className="typo-strong-300 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), 'DD')}</div>
                    <div className="typo-strong-150 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), 'M3').toUpperCase()}</div>
                  </div>
                  <div style={{width: 80}}>
                    <div className="typo-strong-175 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
                    <div className="typo-normal-125 text-center text-brand">{appointment.duration} min</div>
                    <div className="typo-strong-175 text-center text-brand">{DateToFormat(TimestampToDate(appointment.endAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
                  </div>
                </div>

                <div className="w-100 ms-3 ps-3" style={{borderLeft: '1px solid lightgrey'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <input type="text" className="input borderless typo-strong-200 w-100" placeholder="Title..." value={appointment.title} onChange={(e) => changeAppointmentTitle(appointment.id, e.target.value)} onBlur={() => updateAppointmentTitle(appointment.id)} onKeyUp={(e) => keyUpAppointmentTitle(e)} />
                    <div className="d-flex justify-content-end align-items-center w-25">
                      {renderAppointmentStatus(appointment.statusID)}
                      <Drop close={closeAppointmentStatusDrop} className="ms-1">
                        <div className="item rect">
                          <MoreVertical height={14} width={16} background={'none'} fill={'grey'} />
                        </div>
                        <div className="card" style={{width: 200}}>
                          <div className="card-header sm">
                            <div className="typo-normal-150 text-muted">Change status:</div>
                            {renderAppointmentNextStatuses(appointment.id, appointment.statusID)}
                            <div className="typo-normal-150 text-muted">Change status back to:</div>
                            {renderAppointmentPrevStatuses(appointment.id, appointment.statusID)}
                          </div>
                        </div>
                      </Drop>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-top">
                    <div className="w-50 pe-3">
                      <span className="typo-strong-150">Description:</span>
                      <Editor 
                        placeholder="Type..."
                        value={appointment.description}
                        onChangeID={appointment.id}
                        onBlur={updateAppointmentDescription}
                        toolbar="limited"
                        className="text-editor borderless"
                      />
                    </div>
                    <div className="w-50 ps-3">
                      <span className="typo-strong-150">Patient instruction:</span>
                      <Editor 
                        placeholder="Type..."
                        value={appointment.instruction}
                        onChangeID={appointment.id}
                        onBlur={updateAppointmentInstruction}
                        toolbar="limited"
                        className="text-editor borderless"
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-start align-items-center mt-2">
                    <div className="d-flex justify-content-start align-items-center">
                      <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{appointment.appointmentTypeName}</span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="d-flex justify-content-start align-items-center w-75">
                      <UserPicker
                        userList={users.filter((u) => u.roleID === 2)}
                        userID={appointment.userID}
                        onChange={updateAppointmentUser}
                        onChangeID={appointment.id}
                        leftIcon={<UserEdit height={16} width={16} background={'none'} fill={'grey'} />}
                        rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                        className="me-2"
                      />
                    </div>
                    <div className="d-flex justify-content-end align-items-bottom w-25">
                      <div onClick={() => expandAppointmentChat(appointment.id)}>
                        {expandedAppointmentsChats.includes(appointment.id) ? (
                          <div className="item grey">
                            <Comment height={16} width={16} background={'none'} fill={'grey'} />
                            <span className="typo-normal-150">chat</span>
                            <ChevronUp height={14} width={16} background={'none'} fill={'grey'} />
                          </div>
                        ) : (
                          <div className="item grey">
                            <Comment height={16} width={16} background={'none'} fill={'grey'} />
                            <span className="typo-normal-150">chat</span>
                            <ChevronDown height={14} width={16} background={'none'} fill={'grey'} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {expandedAppointmentsChats.includes(appointment.id) && (
              <div className="card-content sm">
                <Chat
                  chatID={appointment.chatID}
                  createMessageCallback={createMessageCallback}
                />
              </div>
            )}
          </div>
        </div>
      );
    });
  }

  function renderAssessments() {
    let list = assessments;
    if (!showTodoAssessments) {
      list = list.filter((t) => t.status !== 1);
    }
    if (!showPausedAssessments) {
      list = list.filter((t) => t.status !== 2);
    }
    if (!showInProgressAssessments) {
      list = list.filter((t) => t.status !== 3);
    }
    if (!showCompletedAssessments) {
      list = list.filter((t) => t.status !== 4);
    }
    if (!showCancelledAssessments) {
      list = list.filter((t) => t.status !== 5);
    }
    if (showOnlyMyAssessments) {
      list = list.filter((t) => t.userID === context.userID);
    }

    return list.map((assessment, i) => {
      return (
        <div key={"assessment_" + i} className="mb-2">
          <div className="card">
            <div className="card-header sm">
              <div className="d-flex justify-content-between align-items-center">
                <div className="typo-strong-200 w-100">{assessment.sequenceNumber}. {assessment.title}</div>
                <div className="d-flex justify-content-end align-items-center w-25">
                  {renderAssessmentStatus(assessment.statusID)}
                  <Drop close={closeAssessmentStatusDrop} className="ms-1">
                    <div className="item rect">
                      <MoreVertical height={14} width={16} background={'none'} fill={'grey'} />
                    </div>
                    <div className="card" style={{width: 200}}>
                      <div className="card-header sm">
                        <div className="typo-normal-150 text-muted">Change status:</div>
                        {renderAssessmentNextStatuses(assessment.id, assessment.statusID)}
                        <div className="typo-normal-150 text-muted">Change status back to:</div>
                        {renderAssessmentPrevStatuses(assessment.id, assessment.statusID)}
                      </div>
                    </div>
                  </Drop>
                </div>
              </div>
              
              <div className="typo-normal-150">{assessment.description}</div>

              <div className="d-flex justify-content-start align-items-center mt-2">
                <div className="d-flex justify-content-start align-items-center">
                  <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{assessment.assessmentTypeName}</span>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="d-flex justify-content-start align-items-center w-100">
                  <div onClick={() => expandAssessmentForm(assessment.id)}>
                    {expandedAssessmentsForms.includes(assessment.id) ? (
                      <div className="item grey">
                        <ListCheck height={16} width={16} background={'none'} fill={'grey'} />
                        <span className="typo-normal-150">form</span>
                        <ChevronUp height={14} width={16} background={'none'} fill={'grey'} />
                      </div>
                    ) : (
                      <div className="item grey">
                        <ListCheck height={16} width={16} background={'none'} fill={'grey'} />
                        <span className="typo-normal-150">form</span>
                        <ChevronDown height={14} width={16} background={'none'} fill={'grey'} />
                      </div>
                    )}
                  </div>

                  <UserPicker
                    userList={users.filter((u) => u.roleID === 2)}
                    userID={assessment.userID}
                    onChange={updateAssessmentUser}
                    onChangeID={assessment.id}
                    leftIcon={<UserEdit height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className="ms-2 me-2"
                  />

                  <RecurrencePicker 
                    recurrenceAt={assessment.recurrenceAt}
                    recurrenceNumber={assessment.recurrenceNumber}
                    recurrenceType={assessment.recurrenceType}
                    onChange={updateAssessmentRecurrence}
                    onChangeID={assessment.id}
                    leftIcon={<Refresh height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className="me-2"
                  />
                </div>
                <div className="d-flex justify-content-end align-items-bottom">
                  <div onClick={() => expandAssessmentChat(assessment.id)}>
                    {expandedAssessmentsChats.includes(assessment.id) ? (
                      <div className="item grey">
                        <Comment height={16} width={16} background={'none'} fill={'grey'} />
                        <span className="typo-normal-150">chat</span>
                        <ChevronUp height={14} width={16} background={'none'} fill={'grey'} />
                      </div>
                    ) : (
                      <div className="item grey">
                        <Comment height={16} width={16} background={'none'} fill={'grey'} />
                        <span className="typo-normal-150">chat</span>
                        <ChevronDown height={14} width={16} background={'none'} fill={'grey'} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {expandedAssessmentsForms.includes(assessment.id) && (
              <>
                <div className="card-header sm">
                  {assessment.questions.map((question, qi) => {
                    return (
                      <div key={"question_" + qi} className={qi === 0 ? "" : "mt-3"}>
                        <div className="typo-strong-175">{qi + 1}. {question.title}</div>
                        {question.description && (
                          <div className="typo-normal-150">{question.description}</div>
                        )}
                        
                        <div className="d-flex justify-content-start align-items-center mt-1">
                          {question.options.map((option, oi) => {
                            return (
                              <div key={"answer_" + oi} className={"item typo-normal-150 me-2 " + ((question.answer === oi+"") ? "blue" : "grey")} onClick={() => updateAssessmentQuestion(question.id, oi+"")}>{option}</div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="card-content sm">
                  {assessment.formulas.map((formula, fi) => {
                    return (
                      <div key={"formula_" + fi} className={"d-flex justify-content-start align-items-center " + (fi === 0 ? "" : "mt-3")}>
                        <div className="ps-1 pe-3">
                            <div className="typo-strong-175">
                            {assessment.questions
                              .filter((question, qi) => formula.questions.includes(qi + 1))
                              .reduce((sum, question) => sum + question.score, 0)}
                            </div>
                        </div>
                        <div>
                          <div className="typo-strong-175">{formula.title}</div>
                          {formula.description && (
                            <div className="typo-normal-150">{formula.description}</div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {expandedAssessmentsChats.includes(assessment.id) && (
              <div className="card-content sm">
                <Chat
                  chatID={assessment.chatID}
                  createMessageCallback={createMessageCallback}
                />
              </div>
            )}
          </div>
        </div>
      )});
  }

  function renderBillings() {
    return <></>;
  }

  function renderDocuments() {
    return (
      <>
        <div className="mb-2">
          <div className="card mb-2">
            <div className="card-header sm">
              <div className="d-flex justify-content-between align-items-center">
                <div className="typo-strong-175 w-100">Project documents</div>
                <div className="item typo-normal-150" onClick={() => triggerProjectFileInput()}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Upload</div>
              </div>
            </div>
            <div className="card-content sm">
              <FilesPreview files={project.files} />
            </div>
          </div>

          {project.patientID && (
            <div className="card">
              <div className="card-header sm">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="typo-strong-175 w-100">Patient documents</div>
                  <div className="item typo-normal-150" onClick={() => triggerPatientFileInput()}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Upload</div>
                </div>
              </div>
              <div className="card-content sm">
                <FilesPreview files={project.patientFiles} />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  function renderProjectStatus(id) {
    const status = project.projectStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial || status.isPaused) {
      statusColor = "grey";
    } 

    if (status.isCompleted) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} text={status.title} />
    );
  }

  function renderProjectNextStatuses() {
    const currentStatus = project.projectStatuses.find((ps) => ps.id === project.statusID);

    if (!currentStatus.next) {
      return (
        <div className="typo-medium-150">
          Project has no next statuses
        </div>
      );
    }

    return project.projectStatuses.filter((status) => currentStatus.next.includes(status.id)).map((status) => {
      return (
        <div className="side-item small nowrap typo-medium-150" onClick={() => changeProjectStatus(status)}>
          To <ArrowRight height={14} width={16} background={'none'} fill={'grey'} /> {renderProjectStatus(status.id)}
        </div>
      );
    })
  }

  function renderProjectPrevStatuses() {
    const currentStatus = project.projectStatuses.find((ps) => ps.id === project.statusID);

    if (!currentStatus.prev) {
      return (
        <div className="typo-medium-150">
          Project has no previous statuses
        </div>
      );
    }

    return project.projectStatuses.filter((status) => currentStatus.prev.includes(status.id)).map((status) => {
      return (
        <div className="side-item small nowrap typo-medium-150" onClick={() => changeProjectStatus(status)}>
          To <ArrowLeft height={14} width={16} background={'none'} fill={'grey'} /> {renderProjectStatus(status.id)}
        </div>
      );
    })
  }

  function renderTaskStatus(id) {
    const status = project.taskStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial || status.isPaused) {
      statusColor = "grey";
    } 

    if (status.isCompleted) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={'small'} text={status.title} />
    );
  }

  function renderTaskPrevStatuses(taskID, statusID) {
    const currentStatus = project.taskStatuses.find((ts) => ts.id === statusID);

    if (!currentStatus.prev) {
      return (
        <div className="typo-medium-150">
          Task has no previous statuses
        </div>
      );
    }

    return project.taskStatuses.filter((status) => currentStatus.prev.includes(status.id)).map((status) => {
      return (
        <div className="side-item small typo-medium-150" onClick={() => changeTaskStatus(taskID, status)}>
          To <ArrowLeft height={14} width={16} background={'none'} fill={'grey'} /> {renderTaskStatus(status.id)}
        </div>
      );
    })
  }

  function renderTaskNextStatuses(taskID, statusID) {
    const currentStatus = project.taskStatuses.find((ts) => ts.id === statusID);

    if (!currentStatus.next) {
      return (
        <div className="typo-medium-150">
          Task has no next statuses
        </div>
      );
    }

    return project.taskStatuses.filter((status) => currentStatus.next.includes(status.id)).map((status) => {
      return (
        <div className="side-item small typo-medium-150" onClick={() => changeTaskStatus(taskID, status)}>
          To <ArrowRight height={14} width={16} background={'none'} fill={'grey'} /> {renderTaskStatus(status.id)}
        </div>
      );
    })
  }

  function renderAppointmentStatus(id) {
    const status = project.appointmentStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial || status.isPaused) {
      statusColor = "grey";
    } 

    if (status.isCompleted) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={'small'} text={status.title} />
    );
  }

  function renderAppointmentPrevStatuses(appointmentID, statusID) {
    const currentStatus = project.appointmentStatuses.find((as) => as.id === statusID);

    if (!currentStatus.prev) {
      return (
        <div className="typo-medium-150">
          Appointment has no previous statuses
        </div>
      );
    }

    return project.appointmentStatuses.filter((status) => currentStatus.prev.includes(status.id)).map((status) => {
      return (
        <div className="side-item small typo-medium-150" onClick={() => changeAppointmentStatus(appointmentID, status)}>
          To <ArrowLeft height={14} width={16} background={'none'} fill={'grey'} /> {renderAppointmentStatus(status.id)}
        </div>
      );
    })
  }

  function renderAppointmentNextStatuses(appointmentID, statusID) {
    const currentStatus = project.appointmentStatuses.find((as) => as.id === statusID);

    if (!currentStatus.next) {
      return (
        <div className="typo-medium-150">
          Appointment has no next statuses
        </div>
      );
    }

    return project.appointmentStatuses.filter((status) => currentStatus.next.includes(status.id)).map((status) => {
      return (
        <div className="side-item small typo-medium-150" onClick={() => changeAppointmentStatus(appointmentID, status)}>
          To <ArrowRight height={14} width={16} background={'none'} fill={'grey'} /> {renderAppointmentStatus(status.id)}
        </div>
      );
    })
  }

  function renderAssessmentStatus(id) {
    const status = project.assessmentStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial || status.isPaused) {
      statusColor = "grey";
    } 

    if (status.isCompleted) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={'small'} text={status.title} />
    );
  }

  function renderAssessmentPrevStatuses(assessmentID, statusID) {
    const currentStatus = project.assessmentStatuses.find((as) => as.id === statusID);

    if (!currentStatus.prev) {
      return (
        <div className="typo-medium-150">
          Assessment has no previous statuses
        </div>
      );
    }

    return project.assessmentStatuses.filter((status) => currentStatus.prev.includes(status.id)).map((status) => {
      return (
        <div className="side-item small typo-medium-150" onClick={() => changeAssessmentStatus(assessmentID, status)}>
          To <ArrowLeft height={14} width={16} background={'none'} fill={'grey'} /> {renderAssessmentStatus(status.id)}
        </div>
      );
    })
  }

  function renderAssessmentNextStatuses(assessmentID, statusID) {
    const currentStatus = project.assessmentStatuses.find((as) => as.id === statusID);

    if (!currentStatus.next) {
      return (
        <div className="typo-medium-150">
          Assessment has no next statuses
        </div>
      );
    }

    return project.assessmentStatuses.filter((status) => currentStatus.next.includes(status.id)).map((status) => {
      return (
        <div className="side-item small typo-medium-150" onClick={() => changeAssessmentStatus(assessmentID, status)}>
          To <ArrowRight height={14} width={16} background={'none'} fill={'grey'} /> {renderAssessmentStatus(status.id)}
        </div>
      );
    })
  }

  function renderProjectPriority() {
    switch (project.priority) {
      case -2:
        return <ChevronDown2 height={16} width={16} background={'none'} fill={'blue'} />;
      case -1:
        return <ChevronDown height={16} width={16} background={'none'} fill={'blue'} />;
      case 0:
        return <Minus2 height={16} width={16} background={'none'} fill={'orange'} />;
      case 1:
        return <ChevronUp height={16} width={16} background={'none'} fill={'red'} />;
      case 2:
        return <ChevronUp2 height={16} width={16} background={'none'} fill={'red'} />;
      default:
        return <Minus2 height={16} width={16} background={'none'} fill={'orange'} />;
    }
  }

  function renderProjectID() {
    return project.id.toString().padStart(project.projectType.projectMask, '0');
  }

  function renderProjectProgress() {
    var initial = 0, progress = 0, completed = 0, total = 0;

    initial = initial + tasks.filter((task) => task.status === 1 || task.status === 2).length;
    progress = progress + tasks.filter((task) => task.status === 3).length;
    completed = completed + tasks.filter((task) => task.status === 4 || task.status === 5).length;
    total = total + tasks.length;

    console.log("initial", initial);
    console.log("progress", progress);
    console.log("completed", completed);
    console.log("total", total);

    initial = initial + appointments.filter((appointment) => appointment.status === 1 || appointment.status === 2).length;
    progress = progress + appointments.filter((appointment) => appointment.status === 3).length;
    completed = completed + appointments.filter((appointment) => appointment.status === 4 || appointment.status === 5).length;
    total = total + appointments.length;

    console.log("initial", initial);
    console.log("progress", progress);
    console.log("completed", completed);
    console.log("total", total);

    initial = initial + assessments.filter((assessment) => assessment.status === 1 || assessment.status === 2).length;
    progress = progress + assessments.filter((assessment) => assessment.status === 3).length;
    completed = completed + assessments.filter((assessment) => assessment.status === 4 || assessment.status === 5).length;
    total = total + assessments.length;

    console.log("initial", initial);
    console.log("progress", progress);
    console.log("completed", completed);
    console.log("total", total);

    return (
      <div className="d-flex justify-content-start align-items-center" style={{width: 220}}>
        <div style={{height: 5, width: (initial / total) * 100 + "%", backgroundColor: "lightgrey"}}></div>
        <div style={{height: 5, width: (progress / total) * 100 + "%", backgroundColor: "#7e9cdf"}}></div>
        <div style={{height: 5, width: (completed / total) * 100 + "%", backgroundColor: "#2b9963"}}></div>
      </div>
    );
  }

  if (loading) {
    return (
      <></>
    );
  }

  return (
    <>
      {changeProjectStatusConfirmationModalVisible && (
        <div className="fixed-bottom modal-bg" onClick={(e) => setChangeProjectStatusConfirmationModalVisible(false)}>
          <div className="container" style={{paddingTop: '5%'}}>
            <div className="row">
              <div className="col-xs-6 col-xs-offset-3">
                <div className="card shadowmore" onClick={(e) => {e.stopPropagation()}}>
                  <div className="card-header">
                    <span className="typo-header-3xLarge">Confirm project status change</span><br/>
                    <span className="typo-normal-175">Choose the proper reason and click on it</span>
                  </div>
                  <div className="card-content mb-4">
                    {project.projectTypeCancellationReasons.map((reason, ri) => {
                      return (
                        <div key={"reason_" + ri} className="item grey me-2" onClick={() => changeProjectStatusConfirm(changeProjectStatusConfirmationStatus, reason.id, reason.title)}>
                          <span className="typo-normal-150">{reason.title}</span>
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="card-content text-end mt-4">
                    <Button onClick={() => changeProjectStatusConfirm(changeProjectStatusConfirmationStatus, project.projectTypeCancellationReasons[0].id, project.projectTypeCancellationReasons[0].title)} color="blue" text="Change" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {createTaskModalVisible && (
        <TaskCreateModal
          projectID={project.id}
          projectTypeID={project.projectTypeID}
          setVisible={setCreateTaskModalVisible}
          createTaskCallback={createTaskCallback}
        />
      )}

      {createAppointmentModalVisible && (
        <AppointmentCreateModal
          projectID={project.id}
          projectTypeID={project.projectTypeID}
          setVisible={setCreateAppointmentModalVisible}
          createAppointmentCallback={createAppointmentCallback}
        />
      )}

      {createAssessmentModalVisible && (
        <AssessmentCreateModal
          projectID={project.id}
          projectTypeID={project.projectTypeID}
          setVisible={setCreateAssessmentModalVisible}
          createAssessmentCallback={createAssessmentCallback}
        />
      )}

      <input ref={projectFileUploadRef} type="file" className="d-none" onChange={changeProjectFileInput} accept=".jpg,.jpeg,.png,.tiff,.pdf" multiple={true} />

      <input ref={patientFileUploadRef} type="file" className="d-none" onChange={changePatientFileInput} accept=".jpg,.jpeg,.png,.tiff,.pdf" multiple={true} />

      <div className="container-fluid">
        <div className="row pb-4">
          <div className={showHistory ? "col-xs-offset-1 col-xs-9" : "col-xs-offset-1 col-xs-10"}>
            <div className="row mb-3">
              <div className="col-xs-12">
                <div className="card">
                  <div className="card-content sm">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="d-flex justify-content-start align-items-center typo-strong-400">
                        <img src={"/files/"+org.logo+"?token=token1"} alt="logo" style={{height: 40}} />
                        
                        <span className="ms-4 me-1">{project.projectType.projectAlias}-{renderProjectID()}</span>
                        
                        <div className="item">
                          <Copy height={16} width={16} background={'none'} fill={'grey'} />
                        </div>

                        <div className="d-flex justify-content-start align-items-center ms-4">
                          <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{project.projectTypeName}</span>
                        </div>
                        
                      </div>
                      <div className="d-flex justify-content-end align-items-center w-25">
                        {renderProjectStatus(project.statusID)}
                        <Drop close={closeProjectStatusDrop} className="ms-1">
                          <div className="item rect">
                            <MoreVertical height={14} width={16} background={'none'} fill={'grey'} />
                          </div>
                          <div className="card" style={{width: 200}}>
                            <div className="card-header sm">
                              <div className="typo-normal-150 text-muted">Change status:</div>
                              {renderProjectNextStatuses()}
                              <div className="typo-normal-150 text-muted">Change status back:</div>
                              {renderProjectPrevStatuses()}
                            </div>
                          </div>
                        </Drop>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-start align-items-center">
                        {/* Priority */}
                        <div className="d-flex justify-content-start align-items-center me-3">
                          <Drop close={closeProjectPriorityDrop}>
                            <div className={"item grey " + (project.priority < 0 ? "blue" : "") + (project.priority > 0 ? "red" : "")}>
                              {renderProjectPriority()}
                              <span className="typo-normal-150">{project.projectType.projectPriorityLabels[project.priority+2]}</span>
                              <ChevronDown height={16} width={16} background={'none'} fill={'grey'} />
                            </div>
                            <div className="card shadowmore" style={{width: 208}}>
                              <div className="card-header sm">
                                <div className="item me-2" onClick={() => changeProjectPriority(-2)}>
                                  <ChevronDown2 height={16} width={16} background={'none'} fill={'blue'} />
                                </div>
                                <div className="item me-2" onClick={() => changeProjectPriority(-1)}>
                                  <ChevronDown height={16} width={16} background={'none'} fill={'blue'} />
                                </div>
                                <div className="item me-2" onClick={() => changeProjectPriority(0)}>
                                  <Minus2 height={16} width={16} background={'none'} fill={'orange'} />
                                </div>
                                <div className="item me-2" onClick={() => changeProjectPriority(1)}>
                                  <ChevronUp height={16} width={16} background={'none'} fill={'red'} />
                                </div>
                                <div className="item" onClick={() => changeProjectPriority(2)}>
                                  <ChevronUp2 height={16} width={16} background={'none'} fill={'red'} />
                                </div>
                              </div>
                            </div>
                          </Drop>
                        </div>

                        {/* User picker */}
                        <div className="d-flex justify-content-start align-items-center me-3">
                          <UserPicker
                            userList={users.filter((user) => user.roleID === 2)}
                            userID={project.userID}
                            onChange={updateProjectUser}
                            onChangeID={project.id}
                            leftIcon={<UserEdit height={16} width={16} background={'none'} fill={'grey'} />}
                            rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                          />
                        </div>

                        {/* Watch list */}
                        <div className="d-flex justify-content-start align-items-center me-3">
                          <Drop close={closeWatchListDrop}>
                            <div className={"item grey"}>
                              <Eye height={16} width={16} background={'none'} fill={'grey'} />
                              <span className="typo-normal-150">{project.watchList ? project.watchList.length : 0}</span>
                              <ChevronDown height={16} width={16} background={'none'} fill={'grey'} />
                            </div>
                            <div className="card shadowmore" style={{minWidth: 200, width: 'fit-content'}}>
                              <div className="card-header sm">
                                <div className="typo-normal-150 text-muted">Actions:</div>
                                <div className="side-item small nowrap" onClick={() => updateProjectWatchList()}>
                                  <span className="typo-normal-150">{(project.watchList && project.watchList.find((id) => id === context.userID)) ? 'Stop watching' : 'Start watching'}</span>
                                </div>
                                <div className="typo-normal-150 text-muted">Watching this project:</div>
                                {(project.watchList && project.watchList.length > 0) ? (
                                  users.filter((u) => project.watchList.includes(u.id)).map((user, i) => {
                                    return (
                                      <div className="side-item small nowrap">
                                        <Avatar url={users.find((u) => u.id === user.id).profileImageName} /><span className="typo-normal-150">{user.firstName + " " + user.lastName + (user.roleID === 2 && user.speciality ? ' | ' + user.speciality : '')}</span>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="typo-medium-150">
                                    No users watching this project
                                  </div>
                                )}
                              </div>
                            </div>
                          </Drop>
                        </div>

                        {/* Patient picker */}
                        <div className="d-flex justify-content-start align-items-center me-3">
                          {project.patientID ? (
                            <UserPreview
                              userList={users.filter((user) => user.roleID === 3)}
                              userID={project.patientID}
                              leftIcon={<Crown height={16} width={16} background={'none'} fill={'grey'} />}
                              rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                            />
                          ) : (
                            <UserPicker
                              userList={users.filter((user) => user.roleID === 3)}
                              userID={project.patientID}
                              onChange={updateProjectPatient}
                              onChangeID={project.id}
                              leftIcon={<Crown height={16} width={16} background={'none'} fill={'grey'} />}
                              rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                            />
                          )}
                        </div>

                        {/* Location picker */}
                        <div className="d-flex justify-content-start align-items-center me-3">
                          {project.locationID ? (
                            <LocationPreview
                              locationList={locations}
                              locationID={project.locationID}
                              leftIcon={<Home height={16} width={16} background={'none'} fill={'grey'} />}
                              rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                            />
                          ) : (
                            <LocationPicker
                              locationList={locations}
                              locationID={project.locationID}
                              onChange={updateProjectLocation}
                              onChangeID={project.id}
                              leftIcon={<Home height={16} width={16} background={'none'} fill={'grey'} />}
                              rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                            />
                          )}
                        </div>
                      </div>
                      {/* {renderProjectProgress()} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-4">
                <div className="card mb-2">
                  <div className="card-header sm d-flex justify-content-between align-items-center">
                    <span className="typo-strong-200">{summaryTabSelected === "summary" ? "Care Plan Overview" : "Care Plan Review"}</span>
                    <div>
                      <div className={"item typo-normal-150 "  + (summaryTabSelected === "summary" ? "active" : "")} onClick={() => setSummaryTabSelected("summary")}>Internal</div>
                      <div className={"item typo-normal-150 ms-2 " + (summaryTabSelected === "public" ? "active" : "")} onClick={() => setSummaryTabSelected("public")}>Public</div>
                    </div>
                  </div>

                  <div className={summaryHeightLimit ? "scrollable" : ""} style={summaryHeightLimit ? {height: "400px", overflowX: 'auto'} : {}}>
                    {summaryTabSelected === "summary" ? (
                      <div className="card-content">

                        <Editor 
                          placeholder="Description..."
                          value={project.summary}
                          onBlur={changeSummary}
                          toolbar="full"
                          className="text-editor borderless"
                        />
                      </div>
                    ) : (
                      <div className="card-content">
                        <Editor 
                          placeholder="Description..."
                          value={project.summaryPublic}
                          onBlur={changeSummaryPublic}
                          toolbar="full"
                          className="text-editor borderless"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center mb-3">
                  {summaryHeightLimit ? (
                    <div className="item typo-normal-150 ms-2" onClick={() => setSummaryHeightLimit(false)}>Show full text</div>
                  ) : (
                    <div className="item typo-normal-150 ms-2" onClick={() => setSummaryHeightLimit(true)}>Hide full text</div>
                  )}
                </div>

                <div className="card mb-3">
                  <div className="card-header sm d-flex justify-content-between align-items-center">
                    <span className="typo-strong-200">Chat</span>
                    <div>
                      <div className={"item typo-normal-150 "  + (chatTabSelected === "chat" ? "active" : "")} onClick={() => setChatTabSelected("chat")}>Internal</div>
                      <div className={"item typo-normal-150 ms-2 " + (chatTabSelected === "public" ? "active" : "")} onClick={() => setChatTabSelected("public")}>Public</div>
                    </div>
                  </div>
                  {chatTabSelected === "chat" ? (
                    <div className="card-content sm">
                      <Chat
                        chatID={project.chatID}
                        createMessageCallback={createMessageCallback}
                      />
                    </div>
                  ) : (
                    <div className="card-content sm">
                      <Chat
                        chatID={project.patientChatID}
                        createMessageCallback={createMessageCallback}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xs-8">
                <div className="ps-4">

                  <div className={`card shadowmore mb-2 ${isSticky ? 'sticky-top' : ''}`} style={isSticky ? {borderTopLeftRadius: 0, borderTopRightRadius: 0} : {zIndex: 1}}>
                    <div className="card-content sm">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-start align-items-center">
                        <Drop close={closeActionFilterDrop}>
                          <div className="item rect" onClick={() => {}}>
                            <Filter height={14} width={16} background={'none'} fill={'grey'} />
                          </div>
                            <div className="card shadowmore" style={{minWidth: 420, width: 'fit-content'}}>
                              <div className="card-header sm">
                                {actionTabFilter === "tasks" ? (
                                  <>
                                    <div className="typo-normal-150">Status filters:</div>
                                    <div className={"item typo-normal-150 me-2 " + (showTodoTasks ? "blue" : "")} onClick={() => setShowTodoTasks(!showTodoTasks)}>
                                      To Do
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showPausedTasks ? "blue" : "")} onClick={() => setShowPausedTasks(!showPausedTasks)}>
                                      Paused
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showInProgressTasks ? "blue" : "")} onClick={() => setShowInProgressTasks(!showInProgressTasks)}>
                                      In Progress
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showCompletedTasks ? "blue" : "")} onClick={() => setShowCompletedTasks(!showCompletedTasks)}>
                                      Completed
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showCancelledTasks ? "blue" : "")} onClick={() => setShowCancelledTasks(!showCancelledTasks)}>
                                      Cancelled
                                    </div>

                                    <div className="typo-normal-150 mt-2">Assignee filters:</div>
                                    <div className={"item typo-normal-150 me-2 " + (!showOnlyMyTasks ? "blue" : "")} onClick={() => setShowOnlyMyTasks(false)}>
                                      Show everyone's tasks
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showOnlyMyTasks ? "blue" : "")} onClick={() => setShowOnlyMyTasks(true)}>
                                      Show my tasks only
                                    </div>
                                  </>
                                ) : actionTabFilter === "appointments" ? (
                                  <>
                                    <div className="typo-normal-150">Status filters:</div>
                                    <div className={"item typo-normal-150 me-2 " + (showTodoAppointments ? "blue" : "")} onClick={() => setShowTodoAppointments(!showTodoAppointments)}>
                                      To Do
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showPausedAppointments ? "blue" : "")} onClick={() => setShowPausedAppointments(!showPausedAppointments)}>
                                      Paused
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showInProgressAppointments ? "blue" : "")} onClick={() => setShowInProgressAppointments(!showInProgressAppointments)}>
                                      In Progress
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showCompletedAppointments ? "blue" : "")} onClick={() => setShowCompletedAppointments(!showCompletedAppointments)}>
                                      Completed
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showCancelledAppointments ? "blue" : "")} onClick={() => setShowCancelledAppointments(!showCancelledAppointments)}>
                                      Cancelled
                                    </div>

                                    <div className="typo-normal-150 mt-2">Assignee filters:</div>
                                    <div className={"item typo-normal-150 me-2 " + (!showOnlyMyAppointments ? "blue" : "")} onClick={() => setShowOnlyMyAppointments(false)}>
                                      Show everyone's appointments
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showOnlyMyAppointments ? "blue" : "")} onClick={() => setShowOnlyMyAppointments(true)}>
                                      Show my appointments only
                                    </div>
                                  </>
                                ) : actionTabFilter === "assessments" ? (
                                  <>
                                    <div className="typo-normal-150">Status filters:</div>
                                    <div className={"item typo-normal-150 me-2 " + (showTodoAssessments ? "blue" : "")} onClick={() => setShowTodoAssessments(!showTodoAssessments)}>
                                      To Do
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showPausedAssessments ? "blue" : "")} onClick={() => setShowPausedAssessments(!showPausedAssessments)}>
                                      Paused
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showInProgressAssessments ? "blue" : "")} onClick={() => setShowInProgressAssessments(!showInProgressAssessments)}>
                                      In Progress
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showCompletedAssessments ? "blue" : "")} onClick={() => setShowCompletedAssessments(!showCompletedAssessments)}>
                                      Completed
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showCancelledAssessments ? "blue" : "")} onClick={() => setShowCancelledAssessments(!showCancelledAssessments)}>
                                      Cancelled
                                    </div>

                                    <div className="typo-normal-150 mt-2">Assignee filters:</div>
                                    <div className={"item typo-normal-150 me-2 " + (!showOnlyMyAssessments ? "blue" : "")} onClick={() => setShowOnlyMyAssessments(false)}>
                                      Show everyone's assessments
                                    </div>
                                    <div className={"item typo-normal-150 me-2 " + (showOnlyMyAssessments ? "blue" : "")} onClick={() => setShowOnlyMyAssessments(true)}>
                                      Show my assessments only
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </Drop>
                          
                          <div className={"item typo-normal-150 ms-2 " + (actionTabFilter === "tasks" ? "active" : "")} onClick={() => switchTab("tasks")}>Tasks</div>
                          <div className={"item typo-normal-150 ms-2 " + (actionTabFilter === "appointments" ? "active" : "")} onClick={() => switchTab("appointments")}>Appointments</div>
                          <div className={"item typo-normal-150 ms-2 " + (actionTabFilter === "assessments" ? "active" : "")} onClick={() => switchTab("assessments")}>Assessments</div>
                          <div className={"item typo-normal-150 ms-2 " + (actionTabFilter === "billings" ? "active" : "")} onClick={() => switchTab("billings")}>Billings</div>
                          <div className={"item typo-normal-150 ms-2 " + (actionTabFilter === "documents" ? "active" : "")} onClick={() => switchTab("documents")}>Documents</div>
                        </div>
                        
                        {actionTabFilter === "tasks" ? (
                          <div className="item typo-normal-150" onClick={setCreateTaskModalVisible}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Create</div>
                        ) : actionTabFilter === "appointments" ? (
                          <div className="item typo-normal-150" onClick={setCreateAppointmentModalVisible}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Create</div>
                        ) : actionTabFilter === "assessments" ? (
                          <div className="item typo-normal-150" onClick={setCreateAssessmentModalVisible}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Create</div>
                        ) : actionTabFilter === "billings" ? (
                          <></>
                          // <div className="item typo-normal-150" onClick={setCreateBillingModalVisible}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Create</div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>

                  {
                    actionTabFilter === "tasks" ? 
                      renderTasks() : 
                    actionTabFilter === "appointments" ? 
                      renderAppointments() : 
                    actionTabFilter === "assessments" ? 
                      renderAssessments() : 
                    actionTabFilter === "billings" ?
                      renderBillings() :
                      renderDocuments()
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={showHistory ? "col-xs-2" : "col-xs-1"}>
            {showHistory ? (
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <span className="typo-strong-175">History</span>
                  <div className="item" onClick={() => setShowHistory(false)}>
                    <Close height={16} width={16} background={'none'} fill={'grey'} />
                  </div>
                </div>
                <div className="card-content">
                  <Events projectID={project.id} taskID={null} appointmentID={null} refresh={refreshHistory} />
                </div>
              </div>
            ) : (
              <div className="" style={{paddingTop: 12}}>
                <div className="item" onClick={() => setShowHistory(true)}>
                  <TimeClock height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">History</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Project;
