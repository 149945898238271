function TimestampToDate(timestamp) {
    return new Date(timestamp * 1000);
}

function DateToTimestamp(date) {
    return Math.floor(date.getTime() / 1000);
}

function NowToTimestamp() {
    return DateToTimestamp(new Date());
}

function DateToFormat(date, format) {
    // Full template is YYYY-MM-DD HH:mm:ss AMPM

    const monthsF = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const daysF = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const months3 = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days3 = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();

    const monthNameF = monthsF[date.getMonth()];
    const dayNameF = daysF[date.getDay()];

    const monthName3 = months3[date.getMonth()];
    const dayName3 = days3[date.getDay()];

    //const padZero = (num) => (num < 10 ? '0' + num : num);

    const replacements = {
        'YYYY': year,
        'MM': padZero(month),
        'DD': padZero(day),
        'HH': padZero(hour),
        'mm': padZero(min),
        'ss': padZero(sec),
        'MF': monthNameF,
        'DF': dayNameF,
        'M3': monthName3,
        'D3': dayName3,
        'AMPM': (hour >= 12 ? hour - 12 : hour) + ':' + padZero(min) + ' ' + (hour >= 12 ? 'PM' : 'AM'),
    };

    return format.replace(/YYYY|MM|DD|HH|mm|ss|M3|D3|MF|DF|AMPM/g, (match) => replacements[match]);
}

function padZero (num) { 
    return num < 10 ? '0' + num : num;
}

function calculateAge(timestamp) {
    const birthDate = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  }

export { TimestampToDate, DateToTimestamp, DateToFormat, NowToTimestamp, calculateAge };