import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import axios from 'axios';

import { Status, Button, Counter, Input, Avatar } from '../components/component.js';

import { AppContext } from '../context.js';

function Settings() {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [slogan, setSlogan] = useState("");
  const [logo, setLogo] = useState(null);

  const fileUploadRef = useRef(null);
  const [uploadFiles, setUploadFiles] = useState([]);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/org/settings/get', {
        
      }),
    ]).then(axios.spread((settings) => {
      setName(settings.data.name);
      setSlogan(settings.data.slogan);
      setLogo(settings.data.logo);
      setLoading(false);
    })).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  function triggerFileInput() {
    fileUploadRef.current.click();
  }

  function changeFileInput(e) {
    var uploaded = [];
    for (var i = 0; i < e.target.files.length; i++) {
      uploaded.push(e.target.files[i]);
    }
    setUploadFiles(uploadFiles.concat(uploaded));
  }

  function save() {
    var data = new FormData();
    data.append('name', name);
    data.append('slogan', slogan);
    data.append('logo', uploadFiles[0]);
    
    let u = context.addSpiner();
    axios.post('/api/admin/org/settings/update', data).then((response) => {
      setLogo(response.data.logo);
      setUploadFiles([]);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  if (loading) {
    return (
      <></>
    )
  }

  return (
    <>
      <input ref={fileUploadRef} type="file" className="d-none" onChange={changeFileInput} accept=".jpg,.jpeg,.png,.tiff" multiple={false} />
    
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="card-header">
                <span className="typo-header-3xLarge">Organization settings</span><br/>
                <span className="typo-normal-175">Description</span>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-xs-2">
                    <div className="side-item active typo-body-large mb-1">General</div>
                    <div className="side-item typo-body-large mb-1">Users</div>
                    <div className="side-item typo-body-large">Projects</div>
                    <div className="side-item typo-body-large">Tasks</div>
                  </div>
                  <div className="col-xs-offset-1 col-xs-9">
                    <div className="row mb-3">
                      <div className="col-xs-4">
                        <div className="typo-strong-175">Name</div>
                        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} className="input borderless typo-normal-175 w-100" />
                      </div>
                      <div className="col-xs-4">
                        <div className="typo-strong-175">Slogan</div>
                        <input type="text" placeholder="Slogan" value={slogan} onChange={(e) => setSlogan(e.target.value)} className="input borderless typo-normal-175 w-100" />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-xs-4">
                        <div className="typo-strong-175">Logo, click on image to upload</div>
                        <img src={"/files/"+logo+"?token=token1"} alt="logo" style={{height: 40}} onClick={() => triggerFileInput()} />
                      </div>
                    </div>

                    <Button onClick={save} color="blue" text="Save" />
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
