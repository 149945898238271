import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate, Link } from "react-router-dom";

import { AppContext } from '../context.js';

function Analytics() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-xs-3">
          <div className="card h-100">
            <div className="card-content">
              <div className="typo-header-3xLarge">Patient Journey</div>

              <Link to={"/analytics/program-attrition"} className="side-item">
                <div>
                  <div className="typo-strong-200">Program Attrition</div>
                  <div className="typo-normal-125">Tracks the number of project.</div>
                </div>
              </Link>

              <Link to={"/analytics/test/1"} className="side-item">
                <div>
                  <div className="typo-strong-200">Volume Report</div>
                  <div className="typo-normal-125">Tracks the number of patients per month, year, and all-time.</div>
                </div>
              </Link>
              
              <Link to={"/analytics/test/2"} className="side-item">
                <div>
                  <div className="typo-strong-200">Journey Completion Report</div>
                  <div className="typo-normal-125">Measures the percentage of completed patient journeys and tracks their average duration.</div>
                </div>
              </Link>
              
              <Link to={"/analytics/test/3"} className="side-item">
                <div>
                  <div className="typo-strong-200">Patient Engagement Report</div>
                  <div className="typo-normal-125">Monitors patient interactions, such as appointments attended, missed, and rescheduled.</div>
                </div>
              </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Patient Satisfaction Report</div>
                  <div className="typo-normal-125">Based on feedback surveys, measures patient satisfaction throughout their journey.</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card h-100">
            <div className="card-content">
              <div className="typo-header-3xLarge">Demographic &amp; Population Health</div>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Demographic Report</div>
                  <div className="typo-normal-125">Breaks down patient population by age, gender, location, and other relevant demographics.</div>
                </div>
              </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Population Health Report</div>
                  <div className="typo-normal-125">Tracks health outcomes across different population segments, identifying trends and areas of concern.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Chronic Condition Report</div>
                  <div className="typo-normal-125">Analyzes the distribution of chronic conditions among the patient population and their management.</div>
                </div>
                </Link>
            </div>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card h-100">
            <div className="card-content">
              <div className="typo-header-3xLarge">Care Coordinator (Admin)</div>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Task Completion Report</div>
                  <div className="typo-normal-125">Tracks the number and type of tasks completed by care coordinators, including average time to completion.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Productivity Report</div>
                  <div className="typo-normal-125">Measures the productivity of care coordinators, including time spent on different tasks and overall workload.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Task Distribution Report</div>
                  <div className="typo-normal-125">Shows the distribution of tasks among care coordinators, highlighting potential imbalances.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Heat Map Report</div>
                  <div className="typo-normal-125">Visualizes busier days and times, helping to identify peak workload periods.</div>
                </div>
                </Link>
            </div>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card h-100">
            <div className="card-content">
              <div className="typo-header-3xLarge">Care Navigator (Clinical)</div>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Escalation Report</div>
                  <div className="typo-normal-125">Tracks how often patients are escalated to different tiers of specialties, identifying trends in care complexity.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Clinical Call Report</div>
                  <div className="typo-normal-125">Monitors the frequency and outcomes of clinical calls between care navigators and patients.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Patient Monitoring Report</div>
                  <div className="typo-normal-125">Tracks how often patients are checked on during their journey, including follow-ups and monitoring activities.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Specialty Referral Report</div>
                 <div className="typo-normal-125">Analyzes referrals to specialists, including the reasons for referral and outcomes.</div>
                </div>
                </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-3">
          <div className="card h-100">
            <div className="card-content">
              <div className="typo-header-3xLarge">Operational Efficiency</div>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Cycle Time Report</div>
                 <div className="typo-normal-125">Measures the average time taken to complete a patient journey from start to finish.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Volume Trend Report</div>
                  <div className="typo-normal-125">Tracks the volume of new patients over time, identifying trends and forecasting future demand.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Utilization Report</div>
                  <div className="typo-normal-125">Measures the utilization of care coordinators and navigators, identifying under- or over-utilization.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Referral Source Report</div>
                  <div className="typo-normal-125">Tracks where new patients are coming from, including referral sources and marketing channels.</div>
                </div>
                </Link>
            </div>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card h-100">
            <div className="card-content">
              <div className="typo-header-3xLarge">Outcome &amp; Quality</div>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Patient Outcome Report</div>
                  <div className="typo-normal-125">Measures patient outcomes based on predefined criteria (e.g., recovery rates, improvement in symptoms).</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Quality of Care Report</div>
                  <div className="typo-normal-125">Tracks adherence to care protocols and guidelines, measuring the quality of care provided.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Patient Follow-up Report</div>
                  <div className="typo-normal-125">Analyzes follow-up care and its impact on patient outcomes.</div>
                </div>
                </Link>
            </div>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card h-100">
            <div className="card-content">
              <div className="typo-header-3xLarge">Financial &amp; ROI</div>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Revenue Report</div>
                  <div className="typo-normal-125">Tracks revenue generated from patient care, broken down by service type, payer, and time period.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Cost of Care Report</div>
                  <div className="typo-normal-125">Measures the cost associated with different patient journeys, including resource utilization.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">ROI Report</div>
                  <div className="typo-normal-125">Calculates the return on investment for different care coordination activities and programs.</div>
                </div>
                </Link>
            </div>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card h-100">
            <div className="card-content">
              <div className="typo-header-3xLarge">Custom &amp; Ad Hoc</div>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Custom Report Builder</div>
                  <div className="typo-normal-125">Allows users to create custom reports based on specific criteria, combining different data sources.</div>
                </div>
                </Link>

              <Link to={"/analytics/analytic"} className="side-item">
                <div>
                  <div className="typo-strong-200">Ad Hoc Report</div>
                  <div className="typo-normal-125">Provides the ability to generate on-demand reports for specific queries or needs.</div>
                </div>
                </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
