import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';

import { Copy, FilePlus, TimeClock, MarkerPin, MoreVertical, ChevronUp, User, Comment, ChevronDown, ChevronDown2, ChevronDown3, ChevronUp2, ChevronUp3, Minus2, Hashtag, ArrowRight, Close, UserPlus } from "../components/icons.js";
import UserPreview from "../components/userPreview.js";

import { Button, Status } from '../components/component.js';

import Editor from "../components/editor.js";
import Chat from "../components/chat.js";

import Logo from '../files/logo-rect.png';

function PatientProjects() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  //let { id } = useParams();
  let projectID = Number(2);

  const [loading, setLoading] = useState(true);

  const [project, setProject] = useState(null);
  const [users, setUsers] = useState([]);

  const [tab, setTab] = useState('overview');
  
  useEffect(() => {
    // axios.post('/api/patient/care/project/get', {
    //   id: projectID,
    // }).then((response) => {
    //   setProject(response.data);
    //   setLoading(false);
    // }).catch((e) => {
    //   console.log(e);
    // });

    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/care/projects/get', {
        id: 1
      }),
      axios.post('/api/admin/iam/users/list', {
        // TODO
      }),
      // axios.post('/api/admin/org/settings/get', {
      //   // TODO
      // }),
    ]).then(axios.spread((project, users) => {
      setProject(project.data);
      setUsers(users.data.users);
      //setOrg(org.data);
      // setLocations(locations.data.locations);
      setLoading(false);
    })).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  function renderTasks() {
    return project.tasks.filter((t) => t.patientTask).map((task, index) => {
      return (
        <div key={index} className="card mb-3">
          <div className="card-content sm">
            <div className="d-flex justify-content-between align-items-center">
              <div className="typo-strong-175">{task.title}</div>
              <div className="typo-normal-150">{renderTaskStatus(task.statusID)}</div>
            </div>

            <div className="typo-normal-150">{task.instruction}</div>

            <div className="d-flex justify-content-start align-items-center w-100 mt-1">
              <UserPreview
                userList={users}
                userID={task.userID}
                onChange={() => {}}
                onChangeID={task.id}
                leftText="Assignee:"
              />
            </div>
          </div>
        </div>
      );
    });
  }

  function renderAppointments() {
    return project.appointments.map((appointment, index) => {
      return (
        <div key={index} className="card mb-3">
          <div className="card-content sm">
            <div className="d-flex justify-content-between align-items-center">
              <div className="typo-strong-175">{appointment.title}</div>
              <div className="typo-normal-150">{renderAppointmentStatus(appointment.statusID)}</div>
            </div>

            <div className="typo-normal-150">{appointment.instruction}</div>

            <div className="d-flex justify-content-start align-items-center w-100 mt-1">
              <UserPreview
                userList={users}
                userID={appointment.userID}
                onChange={() => {}}
                onChangeID={appointment.id}
                leftText="Assignee:"
              />
            </div>
          </div>
        </div>
      );
    });
  }

  function renderTaskStatus(id) {
    const status = project.taskStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial) {
      statusColor = "grey";
    } 

    if (status.isComplete) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={'small'} text={status.title} />
    );
  }

  function renderAppointmentStatus(id) {
    const status = project.appointmentStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial) {
      statusColor = "grey";
    } 

    if (status.isComplete) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={'small'} text={status.title} />
    );
  }

  if (loading) {
    return (<></>);
  }

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-xs-12 h-100">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex justify-content-start align-items-center">
              <img src={Logo} style={{height: 44}} />
              <div className="typo-hero-small ms-2">Hello Kevin</div>
            </div>
            <div className="item" onClick={() => navigate('/profile')}>
              <User height={24} width={24} background={'none'} fill={'grey'} />
            </div>
          </div>
          <div className="card shadowmore mb-3">
            <div className="card-content sm">
              <div className="d-flex justify-content-between align-items-center">
                <div className={"item typo-normal-175 " + (tab === 'overview' ? 'blue' : '')} onClick={() => setTab('overview')}>Overview</div>
                <div className={"item typo-normal-175 " + (tab === 'tasks' ? 'blue' : '')} onClick={() => setTab('tasks')}>Tasks</div>
                <div className={"item typo-normal-175 " + (tab === 'appointments' ? 'blue' : '')} onClick={() => setTab('appointments')}>Appointments</div>
                <div className={"item typo-normal-175 " + (tab === 'chat' ? 'blue' : '')} onClick={() => setTab('chat')}>Chat</div>
              </div>
            </div>
          </div>

          {tab === 'overview' && (
            <div className="card">
              <div className="card-content sm">
                {project.summaryPublic ? (
                  <Editor 
                    placeholder="Description..."
                    value={project.summaryPublic}
                    onBlur={() => {}}
                    readOnly={true}
                    toolbar="full"
                    className="text-editor borderless"
                  />
                ) : (
                  <span className="typo-medium-150">Not posted yet</span>
                )}
              </div>
            </div>
          )}

          {tab === 'tasks' && (
            renderTasks()
          )}

          {tab === 'appointments' && (
            renderAppointments()
          )}

          {tab === 'chat' && (
            <div className="card">
              <div className="card-content sm">
                <Chat
                  chatID={project.patientChatID}
                  autoscroll={true}
                  createMessageCallback={() => {}}
                />
              </div>
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
}

export default PatientProjects;
