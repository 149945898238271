import { useEffect, useState, useContext } from "react";
import { useNavigate, createBrowserRouter, RouterProvider, Link } from "react-router-dom";

import Logo from "../files/logo.svg";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, Bell, Bank } from "../components/icons.js";

import { Avatar, Counter } from "../components/component.js";

import Drop from "../components/drop.js";

import { AppContext } from '../context.js';

import Notification from "./notification.js";

function Navigation(props) {
  const { children } = props;

  const context = useContext(AppContext);

  return (
    <>
      <div className="container-fluid nav">
        <div className="row">
          <div className="col-xs-3 p-2 d-flex justify-content-start align-items-center">
            <img src={Logo} alt="logo" />
          </div>
          {context.roleID === 2 ? (
            <div className="col-xs-6 p-2 d-flex justify-content-center align-items-center">
              <Link to={'/'} className="item typo-body-medium"><Activity height={16} width={16} background={'none'} fill={'grey'} /> Dashboard</Link>
              <Link to={'/projects'} className="item typo-body-medium ms-2"><Folder height={16} width={16} background={'none'} fill={'grey'} /> Projects</Link>
              <Link to={'/patients'} className="item typo-body-medium ms-2"><Users height={16} width={16} background={'none'} fill={'grey'} /> Patients</Link>
              <Link to={'/billing'} className="item typo-body-medium ms-2"><Bank height={16} width={16} background={'none'} fill={'grey'} /> Billing</Link>
              <Link to={'/analytics'} className="item typo-body-medium ms-2"><Speedmeter height={16} width={16} background={'none'} fill={'grey'} /> Analytics</Link>
              <Link to={'/settings'} className="item typo-body-medium ms-2"><Settings height={16} width={16} background={'none'} fill={'grey'} /> Settings</Link>
            </div>
          ) : (
            <div className="col-xs-6 p-2 d-flex justify-content-center align-items-center">
              {/* <Link to={'/'} className="item typo-body-medium"><Activity height={16} width={16} background={'none'} fill={'grey'} /> Dashboard</Link>
              <Link to={'/projects'} className="item typo-body-medium ms-2"><Folder height={16} width={16} background={'none'} fill={'grey'} /> Projects <Counter color="red" text="3" /></Link> */}
            </div>
          )}
          <div className="col-xs-3 p-2 d-flex justify-content-end align-items-center">
            {/* {context.roleID === 2 && (
              <Link to={'/inbox'} className="item typo-body-medium me-2"><Inbox height={16} width={16} background={'none'} fill={'grey'} />Inbox <Counter color="red" text="21" /></Link>
            )}
            {context.roleID === 2 && (
              <Notification />
            )} */}
            <Link to={'/profile'} className="item typo-body-medium"><Avatar url={context.profileImageName} />{context.username}</Link>
          </div>
        </div>
      </div>
      <div className="con">
        {children}
      </div>
    </>
  );
}

export default Navigation;