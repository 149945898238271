import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import axios from 'axios';

import { v4 as uuidv4 } from 'uuid';

import { AppContext } from './context.js';

//import './css/bootstrap-grid.min.css';
import './css/flexboxgrid.min.css';
import './css/app.css';
import './css/utils.css';
import './css/editor.css';

import './css/typo.scss';
import './css/navigation.scss';
import './css/avatar.scss';
import './css/card.scss';
import './css/button.scss';
import './css/input.scss';
import './css/status.scss';
import './css/counter.scss';
import './css/modal.scss';
import './css/datepicker.scss';

// Desktop
import DesktopNavigation from './components/navigation.js';

import DesktopLogin from './desktop/login.js';
import DesktopDashboard from './desktop/dashboard.js';
import DesktopProjects from './desktop/projects.js';
import DesktopProject from './desktop/project.js';
import DesktopPatients from './desktop/patients.js';
import DesktopPatient from './desktop/patient.js';
import DesktopAnalytics from './desktop/analytics.js';
import DesktopAnalytic from './desktop/analytic.js';
import DesktopSettings from './desktop/settings.js';
import DesktopComponents from './desktop/components.js';
import DesktopInbox from './desktop/inbox.js';
import DesktopProfile from './desktop/profile.js';
import DesktopLoginPatient from "./desktop/login-patient.js";
import DesktopProjectCreate from "./desktop/project-create.js";
import DesktopPatientCreate from "./desktop/patient-create.js";
import DesktopAnalyticsTest1 from './desktop/analytics-test1.js';
import DesktopReferral from "./desktop/referral.js";
import DesktopBilling from "./desktop/billing.js";
import DesktopAnalyticsProgramAttrition from "./desktop/analytics/program-attrition.js";

import DesktopPatientProjects from './desktop/patient-projects.js';
import DesktopPatientProject from './desktop/patient-project.js';

// Mobile
import MobileNavigation from './mobile/components/navigation.js';

import MobileLogin from './mobile/login.js';
import MobilePatientProjects from './mobile/patient-projects.js';
import MobileProfile from './mobile/profile.js';
import Spinner from "./components/spinner.js";
import Error from "./components/error.js";

function App() {
  const [loading, setLoading] = useState(true);

  const [token, setToken] = useState(null);

  const [roleID, setRoleID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [username, setUsername] = useState(null);
  const [profileImageName, setProfileImageName] = useState(null);
  const [lang, setLang] = useState(null);
  const [dateFormat, setDateFormat] = useState(null);
  const [timeFormat, setTimeFormat] = useState(null);
  const [theme, setTheme] = useState(null); // TODO

  const [spiners, setSpiners] = useState([]);

  const [errors, setErrors] = useState([]);

  const [scale, setScale] = useState(null);

  // const worker = new SharedWorker('sworker.js');

  // useEffect(() => {
  //   console.log('WORKER');

  //   worker.port.start();

  //   // setInterval(() => {
  //   //   worker.port.postMessage('Hello, SharedWorker!');
  //   // }, 1000);

  //   worker.port.onmessage = function(event) {
  //     console.log('Received from shared worker:', event.data);
  //   };

  //   return () => {
  //     worker.port.close();
  //   }
  // }, [loading]);

  // Browser tab init
  useEffect(() => {
    console.log('INIT');

    // All variables should be loaded from session storage
    let lsToken = localStorage.getItem('token');
    let lsRoleID = localStorage.getItem('roleID');
    let lsUserID = localStorage.getItem('userID');
    let lsUsername = localStorage.getItem('username');
    let lsProfileImageName = localStorage.getItem('profileImageName');
    let lsLang = localStorage.getItem('lang');
    let lsDateFormat = localStorage.getItem('dateFormat');
    let lsTimeFormat = localStorage.getItem('timeFormat');
    let lsTheme = localStorage.getItem('theme'); // TODO

    // If some are empty - request all from server
    // Also needed in case of adding new variables to not break the app for users who already have the app
    if (token !== null && (lsRoleID === null || lsUserID === null || lsUsername === null || lsProfileImageName === null || lsLang === null || lsDateFormat === null || lsTimeFormat === null || lsTheme === null)) {
      // Fabricate server call
      function sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
          currentDate = Date.now();
        } while (currentDate - date < milliseconds);
      }

      sleep(2000);

      // Fabricate server response
      lsToken = 'token2';
      lsRoleID = '1';
      lsUserID = '2';
      lsUsername = 'Marvin Vaca';
      lsProfileImageName = 'avatar.png';
      lsLang = 'en';
      lsDateFormat = 'DD.MM.YYYY';
      lsTimeFormat = '12';
      lsTheme = 'default';

      localStorage.setItem('token', lsToken);
      localStorage.setItem('roleID', lsRoleID);
      localStorage.setItem('userID', lsUserID);
      localStorage.setItem('username', lsUsername);
      localStorage.setItem('profileImageName', lsProfileImageName);
      localStorage.setItem('lang', lsLang);
      localStorage.setItem('dateFormat', lsDateFormat);
      localStorage.setItem('timeFormat', lsTimeFormat);
      localStorage.setItem('theme', lsTheme);
    }

    // Set scale
    let scale;
    let height = window.innerHeight;
    let width = window.innerWidth;
		if (width >= 1200) {
      scale = 'xl';
		} else if (width > 993) {
			scale = 'lg';
		} else if (width > 768) {
			scale = 'md';
		} else if (width > 576) {
			scale = 'sm';
		} else {
			scale = 'xs';
    }
    
    // Set data to session storage
    setToken(lsToken);
    setRoleID(Number(lsRoleID));
    setUserID(Number(lsUserID));
    setUsername(lsUsername);
    setProfileImageName(lsProfileImageName);
    setLang(lsLang);
    setDateFormat(lsDateFormat);
    setTimeFormat(lsTimeFormat);
    setTheme(lsTheme);

    setScale(scale);

    setLoading(false);

    // Set axios token
    axios.defaults.headers.common['Authorization'] = `Bearer ${lsToken}`;

    // Set scale on resize
    window.addEventListener("resize", rescale());
  }, []);

  function rescale() {
    let scale;
    let height = window.innerHeight;
    let width = window.innerWidth;
    if (width >= 1200) {
      scale = 'xl';
    } else if (width > 993) {
      scale = 'lg';
    } else if (width > 768) {
      scale = 'md';
    } else if (width > 576) {
      scale = 'sm';
    } else {
      scale = 'xs';
    }
    setScale(scale);
  }

  // Token manipulation in store
  function setState(newToken, newRoleID, newUserID, newUsername, newProfileImageName, newLang, newDateFormat, newTimeFormat, newTheme) {
    localStorage.setItem('token', newToken);
    localStorage.setItem('roleID', newRoleID);
    localStorage.setItem('userID', newUserID);
    localStorage.setItem('username', newUsername);
    localStorage.setItem('profileImageName', newProfileImageName);
    localStorage.setItem('lang', newLang);
    localStorage.setItem('dateFormat', newDateFormat);
    localStorage.setItem('timeFormat', newTimeFormat);
    localStorage.setItem('theme', newTheme);

    setToken(newToken);
    setRoleID(newRoleID);
    setUserID(newUserID);
    setUsername(newUsername);
    setProfileImageName(newProfileImageName);
    setLang(newLang);
    setDateFormat(newDateFormat);
    setTimeFormat(newTimeFormat);
    setTheme(newTheme);

    //axios.defaults.baseURL = 'https://api.example.com';
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    //axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  function clearState() {
    localStorage.removeItem('token');
    localStorage.removeItem('roleID');
    localStorage.removeItem('userID');
    localStorage.removeItem('username');
    localStorage.removeItem('profileImageName');
    localStorage.removeItem('lang');
    localStorage.removeItem('dateFormat');
    localStorage.removeItem('timeFormat');
    localStorage.removeItem('theme');

    setToken(null);
    setRoleID(null);
    setUserID(null);
    setUsername(null);
    setProfileImageName(null);
    setLang(null);
    setDateFormat(null);
    setTimeFormat(null);
    setTheme(null);

    setSpiners([]);
    setErrors([]);
  }

  function addSpiner() {
    var u = uuidv4();
    var object = {id: u};
    setSpiners([...spiners, object]);
    return u;
  }

  function addError(e) {
    console.log(e.response.status);
    console.log(e.response.data);

    switch (e.response.status) {
      case 401:
        console.log('Unauthorized');
        clearState();
        break;
      default:
        var u = uuidv4();
        var object = {id: u, message: e.response.data.error};
        setErrors([...errors, object]);

        // setTimeout(() => {
        //   setErrors(errors.filter((item) => item.id !== u));
        // }, 5000);
        break;
    }
  }

  function removeSpiner(id) {
    setSpiners(spiners.filter((item) => item.id !== id));
  }
  
  if (loading) {
    return (
      <></>
    );
  }

  return (
    <AppContext.Provider value={{ 
      // App state variables
      token, roleID, userID, username, profileImageName, lang, dateFormat, timeFormat, theme, 
      // System variables
      scale, 
      // App state functions
      setState, clearState, 
      // Spinner functions
      spiners, addSpiner, removeSpiner, 
      // Request error functions
      errors, addError, 
    }}>
      <BrowserRouter>
        {token ? roleID === 2 ? (
          ///////////////////
          // Admin context //
          ///////////////////
          scale !== 'xs' ? (
            <DesktopNavigation>
              <Routes>
                <Route path="/profile" element={<DesktopProfile />} />
                <Route path="/projects" element={<DesktopProjects />} />
                <Route path="/project/create" element={<DesktopProjectCreate />} />
                <Route path="/project/:id" element={<DesktopProject />} />
                <Route path="/patients" element={<DesktopPatients />} />
                <Route path="/patient/create" element={<DesktopPatientCreate />} />
                <Route path="/patient/:id" element={<DesktopPatient />} />
                <Route path="/analytics" element={<DesktopAnalytics />} />
                <Route path="/analytics/analytic" element={<DesktopAnalytic />} />
                <Route path="/analytics/test/1" element={<DesktopAnalyticsTest1 />} />
                <Route path="/settings" element={<DesktopSettings />} />
                <Route path="/components" element={<DesktopComponents />} />
                <Route path="/inbox" element={<DesktopInbox />} />
                <Route path="/billing" element={<DesktopBilling />} />

              ` <Route path="/analytics/program-attrition" element={<DesktopAnalyticsProgramAttrition />} />`

                <Route path="/" element={<DesktopDashboard />} />
              </Routes>
              <Spinner spiners={spiners} />
              <Error errors={errors} />
            </DesktopNavigation>
        ) : (
          <MobileNavigation>
            <Routes>
            </Routes>
          </MobileNavigation>
        )
      ) : (
        /////////////////////
        // Patient context //
        /////////////////////
        scale !== 'xs' ? (
          <DesktopNavigation>
            <Routes>
              <Route path="/profile" element={<DesktopProfile />} />
              <Route path="/project/:id" element={<DesktopPatientProject />} />
              <Route path="*" element={<Navigate to="/project/1" />} />
            </Routes>
          </DesktopNavigation>
        ) : (
          <MobileNavigation>
            <Routes>
              <Route path="/projects" element={<MobilePatientProjects />} />
              <Route path="/profile" element={<MobileProfile />} />
              <Route path="*" element={<Navigate to="/projects" />} />
            </Routes>
          </MobileNavigation>
        )
      ) : (
        ///////////////////
        // Login context //
        ///////////////////
        scale !== 'xs' ? (
          <Routes>
            <Route path="/referral" element={<DesktopReferral />} />
            <Route path="/" element={<DesktopLogin />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        ) : (
          <Routes>
            {/* <Route path="/referral" element={<DesktopReferral />} /> */}
            <Route path="/" element={<MobileLogin />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )
      )}
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
