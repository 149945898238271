import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';

import { Copy, FilePlus, TimeClock, Search, Activity, Minus, CheckLarge, ChevronDown2, Minus2, ChevronUp, ChevronUp2, ChevronDown, UserPlus } from "../components/icons.js";
import { Status, Button, Counter, Input, Avatar } from '../components/component.js';
import Loading from '../components/loading.js';
import Drop from '../components/drop.js';
import Helmet from "../components/helmet.js";
import Editor from "../components/editor.js";
import UserPicker from "../components/userPicker.js";
import LocationPicker from "../components/locationPicker.js";
import { DatePicker, SlotPicker } from "../components/datePicker.js";

function PatientCustomAttributeModal(props) {
  const { data, setVisible, saveCallback } = props;

  const context = useContext(AppContext);

  const [title, setTitle] = useState(data.title);
  const [value, setValue] = useState(data.value);

  function save() {
    let u = context.addSpiner();
    axios.post('/api/admin/patient/profiles/updateCustomAttribute', {
      id: data.id,
      index: data.index,
      title: title,
      value: value,
    }).then((response) => {
      data.index = response.data.index;
      data.title = title;
      data.value = value;
      saveCallback(data);
      setVisible(false);
    }).catch((e) => {
      context.addError(e);
      context.removeSpiner(u);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  return (
    <div className="fixed-bottom modal-bg" onClick={(e) => setVisible(false)}>
      <div className="container" style={{paddingTop: '5%'}}>
        <div className="row">
          <div className="col-xs-6 col-xs-offset-3">
            <div className="card shadowmore" onClick={(e) => {e.stopPropagation()}}>
              <div className="card-header">
                <span className="typo-header-3xLarge">{data.index ? "Edit" : "New"} attribute</span><br/>
                <span className="typo-normal-175">Change title or value of attribute and click Save</span>
              </div>
              <div className="card-content mb-4">
                <div className="row">
                  <div className="col-xs-6 pe-4">
                    <div className="typo-strong-175">Title</div>
                    <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} className="input borderless typo-normal-175 w-100" autoFocus={data.index ? false : true} />
                  </div>
                  <div className="col-xs-6 ps-4">
                    <div className="typo-strong-175">Value</div>
                    <input type="text" placeholder="Value" value={value} onChange={(e) => setValue(e.target.value)} className="input borderless typo-normal-175 w-100" autoFocus={data.index ? true : false} />
                  </div>
                </div>
              </div>
              <div className="card-content text-end mt-4">
                <Button onClick={save} color="blue" text="Save" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientCustomAttributeModal;
