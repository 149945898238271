import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import axios from 'axios';

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, ThumbUp, Heart, Smile, SmileSad, Paperplane, File, Download } from "../components/icons.js";

import { AppContext } from '../context.js';

import { TimestampToDate, DateToTimestamp, DateToFormat } from "../utils/datetime.js";

function FilePreview(props) {
  const { file } = props;

  const context = useContext(AppContext);

  return (
    <div className="d-flex justify-content-start align-items-center mt-2">
      <div className="text-center" style={{width: 30}}>
        
      </div>
      <div className="d-flex justify-content-start align-items-center item w-100">
        <div className="d-flex justify-content-center align-items-center" style={{height: '40px', width: '60px', border: "1px solid #ddd"}}>
          {isFileForPreview(file.uuid + "." + file.extension) ? (
            <img src={"/files/"+file.uuid + "." + file.extension + "?token=token1"} style={{maxHeight: '40px', maxWidth: '100%'}} />
          ) : (
            <Folder height={16} width={16} background={'none'} fill={'grey'} />
          )}
        </div>
        <span className="typo-normal-125 ms-1">{file.name + "." + file.extension}</span>
      </div>
    </div>
  );
}

function Preview(props) {
  const { file } = props;

  const context = useContext(AppContext);

  const downloadFile = (file) => {
    axios({
      url: "/files/" + file.uuid + "." + file.extension + "?token=token1",
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name + "." + file.extension);
      document.body.appendChild(link);
      link.click();
    }
    );
  }

  return (
    <div className="d-flex justify-content-between align-items-center item">
      <div className="d-flex justify-content-center align-items-center" style={{height: '40px', width: '60px', border: "1px solid #ddd"}}>
        {isFileForPreview(file.uuid + "." + file.extension) ? (
          <img src={"/files/"+file.uuid + "." + file.extension + "?token=token1"} style={{maxHeight: '40px', maxWidth: '100%'}} />
        ) : (
          <Folder height={16} width={16} background={'none'} fill={'grey'} />
        )}
      </div>
      <div className="w-100 ps-1">
        <div className="typo-strong-150">{file.name}.{file.extension}</div>
        <div className="typo-normal-125 text-muted">Added {DateToFormat(TimestampToDate(file.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(file.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")} by {file.createdByName}</div>
      </div>
      <div className="item" onClick={() => downloadFile(file)}>
        <Download height={16} width={16} background={'none'} fill={'grey'} />
      </div>
    </div>
  );
}

function FilesPreview(props) {
  const { files } = props;

  const context = useContext(AppContext);

  if (files.length === 0) {
    return (
      <span className="typo-normal-150">No files</span>
    );
  }

  return (
    <>
      {/* <div className="d-flex justify-content-between align-items-center">
        <span className="typo-normal-125 ms-1">{files.length} files</span>
      </div> */}
      {files.sort((a, b) => b.createdAt - a.createdAt).map((file) => {
        return (
          <Preview file={file} />
        );
      })}
    </>
  );
}

function isFileForPreview(filename) {
  var extentionForPreview = ['jpg', 'jpeg', 'png', 'tiff'];
  if (extentionForPreview.includes(filename.split('.').pop())) {
    return true;
  }
  return false;
}

export { FilesPreview, FilePreview, isFileForPreview };